import * as React from "react";
import { FC } from "react";
import { Flex } from "@chakra-ui/react";
import FaqItem, { IFaqItem } from "components/Faq/FaqItem";

interface IFaqColumn {
  faqs: IFaqItem[];
}

const FaqColumn: FC<IFaqColumn> = ({ faqs }) => {
  return (
    <Flex
      w={{ base: "100%", md: "50%" }}
      px={{ base: "0", md: "15px" }}
      py={{ base: "10px", md: "0" }}
      direction="column"
    >
      {faqs?.map(({ question, answer }, index) => (
        <FaqItem key={index} question={question} answer={answer} />
      ))}
    </Flex>
  );
};

export default FaqColumn;
