import * as React from "react";
import { FC, useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { Flex, Text, Button, Image, useToast, Spinner } from "@chakra-ui/react";
import Wrapper from "components/Wrapper";
import Uploader from "components/Uploader";
import FileThumb from "components/FileThumb";
import Divider from "components/Divider";
import ModalComponent from "components/Modal";
import ModalWriteOrLoad from "components/ModalWriteOrLoad";
import { palette } from "theme/theme";
import PrescriptionImage from "assets/prescription.png";
import ReportImage from "assets/medical_report.png";
import OtherImage from "assets/other.png";
import {
  deleteSingleAttachmentRequest,
  uploadRequest,
} from "redux/attachments/actions";
import {
  setSingleAppointmentSubmittedRequest,
  getSingleAppointmentRequest,
} from "redux/appointments/actions";
import {
  reduxAllAttachments,
  reduxConsultationExecutedAt,
} from "redux/attachments/selectors";
import { reduxIsLoadingAppointments } from "redux/appointments/selectors";
import { getPatientInfoRequest, resetPatient } from "redux/patient/actions";
import { reduxPatient, reduxIsLoadingPatient } from "redux/patient/selectors";
import { SIZE_LIMIT } from "constants/index";

type DocumentType = "recipe" | "report" | "other";

interface IParams {
  consultationId: string;
}

const SubmitPage: FC = () => {
  const dispatch = useDispatch();
  const attachments = useSelector(reduxAllAttachments);
  const consultationExecutedAt = useSelector(reduxConsultationExecutedAt);
  const isLoadingAppointments = useSelector(reduxIsLoadingAppointments);
  const [toBeDeleted, setToBeDeleted] = useState(null);
  const [documentType, setDocumentType] = useState<DocumentType>("other");

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isWriteOrLoadModalOpen, setIsWriteOrLoadModalOpen] = useState(false);
  const [modal, setModal] = useState({
    title: "",
    subtitle: "",
    content: "",
    actionText: "",
    secondaryActionText: "",
  });
  const hiddenFileInput = useRef<HTMLInputElement>(null!);
  const hiddenFileInputWriteOrLoad = useRef<HTMLInputElement>(null!);

  const { fullName: patientFullName } = useSelector(reduxPatient);
  const isLoadingPatient = useSelector(reduxIsLoadingPatient);

  const { consultationId } = useParams<IParams>();
  const history = useHistory();
  const toast = useToast();

  useEffect(() => {
    dispatch(getPatientInfoRequest({ consultationId }));
    dispatch(getSingleAppointmentRequest({ consultationId }));

    return () => {
      dispatch(resetPatient());
    };
  }, [dispatch, consultationId]);

  const toggleDeleteModal = useCallback(
    () => setIsDeleteModalOpen((open) => !open),
    [],
  );
  const toggleSubmitModal = useCallback(
    () => setIsSubmitModalOpen((open) => !open),
    [],
  );
  const toggleSuccessModal = useCallback(
    () => setIsSuccessModalOpen((open) => !open),
    [],
  );
  const toggleWriteOrLoadModal = useCallback(
    () => setIsWriteOrLoadModalOpen((open) => !open),
    [],
  );

  const changeDocumentAndToggleModal = useCallback(
    (documentType) => {
      setDocumentType(documentType);
      toggleWriteOrLoadModal();
    },
    [toggleWriteOrLoadModal],
  );

  const navigateToHome = useCallback(() => history.push("/"), [history]);

  const openDeleteModal = useCallback(({ uuid, languages }) => {
    const hasTranslations = languages?.length > 0;
    const modalContent = hasTranslations
      ? "Sei sicuro di voler eliminare il documento? Verranno eliminate anche le traduzioni elaborate per il documento."
      : "Sei sicuro di voler eliminare il documento?";

    setToBeDeleted(uuid);
    setModal({
      title: "Attenzione",
      subtitle: "",
      content: modalContent,
      actionText: "Elimina",
      secondaryActionText: "Annulla",
    });
    setIsDeleteModalOpen(true);
  }, []);

  const openSubmitModal = useCallback(() => {
    setModal({
      title: `Confermi di voler inviare i documenti a ${patientFullName}?`,
      subtitle:
        "Attenzione: una volta inviati i documenti non sarà possibile caricarne di nuovi.",
      content: "",
      actionText: "Sì, invia",
      secondaryActionText: "No, torna indietro",
    });
    setIsSubmitModalOpen(true);
  }, [patientFullName]);

  const handleDelete = useCallback(() => {
    let uuid;
    if (toBeDeleted !== null) {
      uuid = toBeDeleted;

      dispatch(deleteSingleAttachmentRequest({ consultationId, uuid }));
      setToBeDeleted(null);
      setIsDeleteModalOpen(false);
    }
  }, [dispatch, consultationId, toBeDeleted]);

  const handleSubmit = useCallback(async () => {
    setIsSubmitModalOpen(false);
    dispatch(setSingleAppointmentSubmittedRequest({ consultationId }));
  }, [dispatch, consultationId]);

  const handleFile = useCallback(
    async (incomingFiles, { allowedFormats, allowedMimeTypes }) => {
      // Close writeOrLoadModal if handleFile was called from there
      if (isWriteOrLoadModalOpen) {
        toggleWriteOrLoadModal();
      }

      // Check file sizes and extensions
      const filesArray = [...incomingFiles];
      const tooBigFiles: Array<string> = [];
      const notAllowedFiles: Array<string> = [];
      const arrayAllowed: Array<string> = allowedMimeTypes
        .split(",")
        .map((str) => str.trim());

      filesArray.forEach((file) => {
        if (file.size > SIZE_LIMIT) {
          tooBigFiles.push(file.name);
          return;
        }

        if (!arrayAllowed.includes(file.type)) {
          notAllowedFiles.push(file.name);
          return;
        }
      });

      if (tooBigFiles.length > 0) {
        toast.closeAll();
        toast({
          title: "Attenzione",
          description: `Uno o più file superano la dimensione massima consentita (40 Mb) e sono stati scartati: ${tooBigFiles.join()}. Si prega di selezionare nuovamente i file che si desidera inviare al paziente.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (notAllowedFiles.length > 0) {
        toast({
          title: "Attenzione",
          description: `Uno o più file non sono stati accettati: ${notAllowedFiles.join()}. Carica i documenti nei formati ${allowedFormats}.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      dispatch(
        uploadRequest({ files: filesArray, consultationId, documentType }),
      );
    },
    [
      dispatch,
      consultationId,
      toast,
      isWriteOrLoadModalOpen,
      toggleWriteOrLoadModal,
      documentType,
    ],
  );

  const renderAttachments = useCallback(() => {
    if (isLoadingAppointments || isLoadingPatient) {
      return (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="lg"
          m="0 auto"
        />
      );
    }

    if (attachments?.length === 0 && !isLoadingAppointments) {
      return (
        <Text fontSize="xl" textAlign="center">
          Non hai caricato alcun documento
        </Text>
      );
    }

    if (attachments?.length > 0 && !isLoadingPatient) {
      return attachments?.map((attachment) => (
        <FileThumb
          key={attachment?.uuid}
          documentId={attachment?.uuid}
          consultationId={consultationId}
          consultationDate={consultationExecutedAt}
          patientName={patientFullName}
          type={attachment?.type}
          kind={attachment?.kind}
          handleDelete={() => openDeleteModal(attachment)}
          languages={attachment.languages}
          page="Submit"
        />
      ));
    }
  }, [
    attachments,
    consultationExecutedAt,
    consultationId,
    patientFullName,
    isLoadingPatient,
    isLoadingAppointments,
    openDeleteModal,
  ]);

  return (
    <Flex
      flex="1"
      direction="column"
      justify="center"
      bg={palette.LIGHT_BLUE}
      py="20px"
    >
      <Wrapper>
        <Text
          fontSize="3xl"
          fontWeight="bold"
          textAlign="center"
          color={palette.BLUE}
        >
          Scegli i documenti da caricare
        </Text>

        <Flex
          p={{ base: "0 0 40px 0", md: "40px 0" }}
          direction={{ base: "column", sm: "row" }}
          justifyContent={{ base: "center", md: "space-between" }}
          flexWrap={{ base: "nowrap", sm: "wrap" }}
          w="60%"
        >
          <Flex
            direction="column"
            align="center"
            mt={{ base: "20px", md: "0" }}
            flexBasis={{ base: "auto", sm: "40%", md: "30%" }}
            onClick={() => changeDocumentAndToggleModal("report")}
          >
            <Flex
              direction="column"
              align="center"
              mt={{ base: "20px", md: "0" }}
              cursor="pointer"
            >
              <Image src={ReportImage} boxSize="70px" m="0 auto" />
              <Text
                fontSize="xl"
                fontWeight="bold"
                textTransform="uppercase"
                mt="20px"
                textAlign="center"
                color={palette.BLUE}
              >
                Referto
              </Text>
            </Flex>
          </Flex>
          <Flex
            direction="column"
            align="center"
            mt={{ base: "20px", md: "0" }}
            flexBasis={{ base: "auto", sm: "40%", md: "30%" }}
            ml={{ sm: "20px", md: "0" }}
            onClick={() => changeDocumentAndToggleModal("recipe")}
          >
            <Flex
              direction="column"
              align="center"
              mt={{ base: "20px", md: "0" }}
              cursor="pointer"
            >
              <Image src={PrescriptionImage} boxSize="70px" m="0 auto" />
              <Text
                fontSize="xl"
                fontWeight="bold"
                textTransform="uppercase"
                mt="20px"
                textAlign="center"
                color={palette.BLUE}
              >
                Ricetta
              </Text>
            </Flex>
          </Flex>
          <Flex
            direction="column"
            align="center"
            mt={{ base: "20px", md: "0" }}
            flexBasis={{ base: "auto", sm: "40%", md: "30%" }}
            onClick={() => setDocumentType("other")}
          >
            <Flex
              direction="column"
              align="center"
              mt={{ base: "20px", md: "0" }}
              cursor="pointer"
              onClick={() => hiddenFileInput.current.click()}
            >
              <Image src={OtherImage} boxSize="70px" m="0 auto" />
              <Text
                fontSize="xl"
                fontWeight="bold"
                textTransform="uppercase"
                textAlign="center"
                mt="20px"
                color={palette.BLUE}
              >
                Altri documenti
              </Text>
              <Uploader
                multiple
                formats=".pdf, .png, .jpeg, .jpg, .doc, .docx"
                accept="application/pdf, image/png, image/jpeg, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                handleFile={handleFile}
                ref={hiddenFileInput}
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex direction="column" w="60%">
          <Text
            fontSize="xl"
            fontWeight="bold"
            textAlign="center"
            color={palette.BLUE}
          >
            Ecco il riepilogo dei documenti caricati da inviare al paziente
          </Text>
          <Text
            textAlign="center"
            fontStyle="italic"
            mb="10px"
            color={palette.BLUE}
          >
            I documenti caricati saranno presenti nel sistema per 24 ore, se non
            inviati. Dopo le 24 ore sarà necessario ricaricare la
            documentazione. Inoltre, nel rispetto delle norme della privacy,
            dopo 7 giorni dall’invio tutti i documenti saranno automaticamente
            cancellati dal sistema.
          </Text>
          <Divider />
          {renderAttachments()}
        </Flex>
      </Wrapper>

      <Flex
        justify={{ base: "center", sm: "flex-end" }}
        mx="auto"
        mt="20px"
        px={{ base: "20px", md: "40px" }}
        w="100%"
        maxW="1200px"
      >
        <Button
          color={palette.WHITE}
          colorScheme={attachments.length === 0 ? "myGray" : "myGreen"}
          px="40px"
          textTransform="uppercase"
          fontWeight="bold"
          isDisabled={attachments.length === 0}
          onClick={openSubmitModal}
        >
          Invia
        </Button>
      </Flex>

      <ModalComponent
        open={isDeleteModalOpen}
        handleModalState={toggleDeleteModal}
        handleAction={handleDelete}
        hasSecondaryButton
        {...modal}
      />

      <ModalComponent
        open={isSuccessModalOpen}
        handleModalState={toggleSuccessModal}
        handleAction={navigateToHome}
        hasSecondaryButton={false}
        {...modal}
      />

      <ModalComponent
        open={isSubmitModalOpen}
        handleModalState={toggleSubmitModal}
        handleAction={handleSubmit}
        hasSecondaryButton
        {...modal}
      />

      <ModalWriteOrLoad
        open={isWriteOrLoadModalOpen}
        consultationId={consultationId}
        handleModalState={toggleWriteOrLoadModal}
        handleFile={handleFile}
        documentType={documentType}
        hiddenRef={hiddenFileInputWriteOrLoad}
      />
    </Flex>
  );
};

export default SubmitPage;
