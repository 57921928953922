import * as React from "react";
import { FC } from "react";
import {
  Text,
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { palette } from "theme/theme";

interface IModalComponent {
  open: boolean;
  handleModalState: () => void;
  title: string;
  subtitle?: string;
  content: string;
  actionText: string;
  secondaryActionText?: string;
  handleAction: () => void;
  hasSecondaryButton?: boolean;
  isClosable?: boolean;
}

const ModalComponent: FC<IModalComponent> = ({
  open,
  handleModalState,
  title,
  subtitle = "",
  content,
  actionText,
  secondaryActionText,
  handleAction,
  hasSecondaryButton = true,
  isClosable = true,
}) => {
  return (
    <Modal
      size="xl"
      isOpen={open}
      onClose={handleModalState}
      isCentered
      closeOnEsc={isClosable}
      closeOnOverlayClick={isClosable}
    >
      <ModalOverlay />
      <ModalContent backgroundColor={palette.AZURE} borderRadius="25px">
        <ModalHeader
          textAlign="center"
          w="70%"
          m="0 auto"
          color={palette.WHITE}
          fontWeight="bold"
        >
          {title}
        </ModalHeader>

        {subtitle && (
          <Box
            textAlign="center"
            w="70%"
            m="0 auto"
            color={palette.WHITE}
            fontSize="s"
          >
            <Text>{subtitle}</Text>
          </Box>
        )}

        {isClosable && <ModalCloseButton color={palette.WHITE} />}

        <ModalBody color={palette.WHITE} m="0 auto">
          <Text>{content}</Text>
        </ModalBody>

        <ModalFooter
          flexDirection={{ base: "column", md: "row" }}
          justifyContent="center"
          w="70%"
          m="0 auto"
          maxW={{ base: "250px", md: "none" }}
        >
          <Button
            size="sm"
            borderRadius="10px"
            colorScheme="myGreen"
            mr={hasSecondaryButton ? 3 : 0}
            onClick={handleAction}
          >
            {actionText}
          </Button>
          {hasSecondaryButton && (
            <Button
              size="sm"
              borderRadius="10px"
              colorScheme="myTransparent"
              mr={3}
              onClick={handleModalState}
              mt={{ base: "10px", md: "0" }}
            >
              {secondaryActionText}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalComponent;
