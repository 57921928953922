import * as React from "react";
import { FC, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FontFaceObserver from "fontfaceobserver";
import { useToast } from "@chakra-ui/react";
import { Switch, Route } from "react-router-dom";
import { reduxMessage } from "redux/messages/selectors";
import { resetMessage } from "redux/messages/actions";
import { reduxIsSpinnerVisible } from "redux/spinner/selectors";
import { useAuth } from "hooks";
import { ROLES } from "constants/index";
import PrivateRoute from "components/PrivateRoute";
import Header from "components/Header";
import ScrollToTop from "components/ScrollToTopOnMount";
import Spinner from "components/Spinner";
import Homepage from "pages/Homepage";
import Faqs from "pages/Faqs";
import Profile from "pages/Profile";
import Show from "pages/Show";
import NotFound from "pages/NotFound";
import AuthCallback from "pages/AuthCallback";
import Logout from "pages/Logout";
import Create from "pages/Create";
import Edit from "pages/Edit";
import Submit from "pages/Submit";

const App: FC = () => {
  const dispatch = useDispatch();
  const message = useSelector(reduxMessage);
  const isVisible = useSelector(reduxIsSpinnerVisible);
  const toast = useToast();
  const { timeoutRef, checkTokenRenewal, refreshAuth } = useAuth();

  const font = new FontFaceObserver("Nunito+Sans");
  const html = document.documentElement;

  useLayoutEffect(() => {
    html.classList.add("fonts-loading");

    font
      .load()
      .then(function () {
        html.classList.remove("fonts-loading");
        html.classList.add("fonts-loaded");
      })
      .catch(function () {
        html.classList.remove("fonts-loading");
        html.classList.add("fonts-failed");
      });
  });

  useEffect(() => {
    if (message?.description) {
      toast({
        title: message.title,
        description: message.description,
        status: message.type,
        duration: 3000,
        isClosable: true,
      });

      setTimeout(() => {
        dispatch(resetMessage());
      }, 3000);
    }
  }, [message, toast, dispatch]);

  useEffect(() => {
    refreshAuth();
  }, [refreshAuth]);

  useEffect(() => {
    const ref = timeoutRef;

    checkTokenRenewal();
    return () => window.clearTimeout(ref.current);
  }, [checkTokenRenewal, timeoutRef]);

  return (
    <>
      <Header />
      <ScrollToTop />
      <Spinner visible={isVisible} />

      <Switch>
        <PrivateRoute exact path="/" component={Homepage} />
        <PrivateRoute path="/faqs" component={Faqs} />
        <PrivateRoute path="/profile" component={Profile} />
        <Route path="/auth/callback*" component={AuthCallback} />
        <Route path="/logout" component={Logout} />
        <PrivateRoute
          path="/consultation/:consultationId/show"
          component={Show}
          roles={[ROLES.doctor, ROLES.member]}
        />
        <PrivateRoute
          path="/consultation/:consultationId/:documentType/:documentId/write"
          component={Edit}
        />
        <PrivateRoute
          path="/consultation/:consultationId/:documentType/write"
          component={Create}
        />
        <PrivateRoute
          path="/consultation/:consultationId/submit"
          component={Submit}
        />
        <Route component={NotFound} />
      </Switch>
    </>
  );
};

export default App;
