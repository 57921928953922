import { FormControl } from "@chakra-ui/react";
import styled from "@emotion/styled";

const FormInputWrapper = styled(FormControl)`
  & + & {
    margin-top: 20px;
  }
`;

export default FormInputWrapper;
