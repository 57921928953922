import { createAction } from "@reduxjs/toolkit";
import {
  IGetPatientInfoRequestPayload,
  IGetPatientInfoSuccessPayload,
} from "./types";

export const getPatientInfoRequest = createAction<IGetPatientInfoRequestPayload>(
  "GET_PATIENT_INFO_REQUEST",
);
export const getPatientInfoSuccess = createAction<IGetPatientInfoSuccessPayload>(
  "GET_PATIENT_INFO_SUCCESS",
);
export const getPatientInfoFailed = createAction("GET_PATIENT_INFO_FAILED");

export const resetPatient = createAction("RESET_PATIENT");
