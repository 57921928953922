import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

const CardSection = styled(Box)`
  max-width: 900px;

  & + & {
    margin-top: 30px;
  }
`;

export default CardSection;
