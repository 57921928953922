import { Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";

const ButtonsWrapper = styled(Flex)`
  & button + a,
  & p + a {
    margin-top: 10px;
  }
`;

export default ButtonsWrapper;
