import * as React from "react";
import { FC, useEffect } from "react";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import Wrapper from "components/Wrapper";
import { palette } from "theme/theme";

interface IRedirectPage {
  to: string;
}

const RedirectPage: FC<IRedirectPage> = ({ to }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return (
    <Flex flex="1" bg={palette.LIGHT_BLUE}>
      <Wrapper>
        <Spinner color={palette.BLUE} />
        <Text color={palette.BLUE}>Caricamento pagina in corso...</Text>
      </Wrapper>
    </Flex>
  );
};

export default RedirectPage;
