import { createReducer } from "@reduxjs/toolkit";
import {
  getUserRequest,
  getUserSuccess,
  getUserFailed,
  getNewTokenRequest,
  getNewTokenSuccess,
  getNewTokenFailed,
  resetAuth,
  getRoleSuccess,
  getRoleFailed,
  updateUserInfoRequest,
  updateUserInfoSuccess,
  updateUserInfoFailed,
  getSignatureRequest,
  getSignatureSuccess,
  getSignatureFailed,
} from "./actions";
import { ICognitoUserAttributes } from "./types";

export interface IUser {
  cognitoUserAttributes: ICognitoUserAttributes;
  email: string;
  firstName: string;
  lastName: string;
}

export interface IAuthState {
  expiresAt: number | undefined;
  isAuthenticated: boolean;
  loading: boolean;
  renewAt: number | undefined;
  role: string | undefined;
  token: string | undefined;
  user: IUser | undefined;
  isSubmitting: boolean;
  isCheckingSignature: boolean;
  hasSignature: boolean | undefined;
}

export const initialState: IAuthState = {
  expiresAt: undefined,
  isAuthenticated: false,
  loading: false,
  renewAt: undefined,
  role: undefined,
  token: undefined,
  user: undefined,
  isSubmitting: false,
  isCheckingSignature: false,
  hasSignature: undefined,
};

const usersReducer = createReducer(initialState, {
  [getUserRequest.type]: (state, action) => {
    state.loading = true;
    state.token = action.payload.token;
  },
  [getUserSuccess.type]: (state, action) => {
    state.loading = false;
    state.user = action.payload.user;
    state.isAuthenticated = true;
    state.expiresAt = action.payload.expiresAt;
    state.renewAt = action.payload.renewAt;
  },
  [getUserFailed.type]: (state) => {
    state.loading = false;
    state.isAuthenticated = initialState.isAuthenticated;
    state.user = initialState.user;
    state.token = initialState.token;
  },
  [getNewTokenRequest.type]: (state) => {
    state.loading = true;
  },
  [getNewTokenSuccess.type]: (state, action) => {
    state.loading = false;
    state.token = action.payload.token;
    state.expiresAt = action.payload.expiresAt;
    state.renewAt = action.payload.renewAt;
  },
  [getNewTokenFailed.type]: (state) => {
    state.loading = false;
  },
  [resetAuth.type]: (state) => {
    state.loading = false;
    state.isAuthenticated = initialState.isAuthenticated;
    state.user = initialState.user;
    state.token = initialState.token;
    state.expiresAt = initialState.expiresAt;
    state.renewAt = initialState.renewAt;
    state.role = initialState.role;
  },
  [getRoleSuccess.type]: (state, action) => {
    state.role = action.payload.role;
  },
  [getRoleFailed.type]: (state) => {
    state.role = initialState.role;
  },
  [updateUserInfoRequest.type]: (state) => {
    state.isSubmitting = true;
  },
  [updateUserInfoSuccess.type]: (state, action) => {
    state.isSubmitting = false;
    state.user = action.payload.user;
  },
  [updateUserInfoFailed.type]: (state) => {
    state.isSubmitting = false;
  },
  [getSignatureRequest.type]: (state) => {
    state.isCheckingSignature = true;
  },
  [getSignatureSuccess.type]: (state, action) => {
    state.isCheckingSignature = false;
    state.hasSignature = action.payload.hasSignature;
  },
  [getSignatureFailed.type]: (state) => {
    state.isCheckingSignature = false;
    state.hasSignature = false;
  },
});

export default usersReducer;
