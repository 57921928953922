import { createSelector } from "@reduxjs/toolkit";
// Fix for circular dependencies error
// import { RootState } from "../store";

const allAttachmentsSelector = (state) => state.attachments.all;
const consultationExecutedAtSelector = (state) =>
  state.attachments.consultationExecutedAt;
const loadingSelector = (state) => state.attachments.loading;
const loadingLanguagesSelector = (state) => state.attachments.loadingLanguages;

const attachmentTextSelector = (state) =>
  state.attachments.active.attachmentText;
const checkedAndOrderedLanguagesSelector = (state) =>
  state.attachments.active.checkedLanguages;
const isUploadingSelector = (state) => state.attachments.uploading;

export const reduxAllAttachments = createSelector(
  allAttachmentsSelector,
  (attachments) => attachments,
);

export const reduxConsultationExecutedAt = createSelector(
  consultationExecutedAtSelector,
  (consultationExecutedAt) => consultationExecutedAt,
);

export const reduxIsLoadingAttachments = createSelector(
  loadingSelector,
  (loading) => loading,
);

export const reduxIsLoadingLanguages = createSelector(
  loadingLanguagesSelector,
  (loadingLanguages) => loadingLanguages,
);

export const reduxAttachmentText = createSelector(
  attachmentTextSelector,
  (attachmentText) => attachmentText,
);

export const reduxCheckedAndOrderedLanguages = createSelector(
  checkedAndOrderedLanguagesSelector,
  (checkedLanguages) => checkedLanguages,
);

export const reduxIsUploadingAttachments = createSelector(
  isUploadingSelector,
  (uploading) => uploading,
);
