import * as React from "react";
import { FC, useEffect, useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { Flex } from "@chakra-ui/react";
import Wrapper from "components/Wrapper";
import { palette } from "theme/theme";
import FaqColumn from "components/Faq/FaqColumn";
import { reduxRole } from "redux/auth/selectors";
import { nonExpiryAccessToken } from "constants/index";
import { splitArray, getPazientiApiEnpoint } from "utils";
import { ROLES } from "constants/index";

const FaqsPage: FC = () => {
  const role = useSelector(reduxRole);
  const [faqs, setFaqs] = useState([]);
  const apiEndpoint = getPazientiApiEnpoint();

  const fetchData = useCallback(async () => {
    try {
      const param =
        role === ROLES.doctor ? "doctor-documents" : "patient-documents";
      const response = await fetch(`${apiEndpoint}/faqs?scope=${param}`, {
        headers: {
          Authorization: `Bearer ${nonExpiryAccessToken}`,
          "Custom-Origin": "pazienti",
          "Content-Type": "application/json",
        },
      }).then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      });
      setFaqs(response?.data?.faqs);
    } catch (e) {
      console.error("fetchData:", e);
    }
  }, [role, apiEndpoint]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [firstHalf, secondHalf] = useMemo(() => splitArray(faqs), [faqs]);

  return (
    <Flex flex="1" bg={palette.LIGHT_BLUE}>
      <Wrapper>
        <Flex w="100%" direction={{ base: "column", md: "row" }}>
          <FaqColumn faqs={firstHalf} />
          <FaqColumn faqs={secondHalf} />
        </Flex>
      </Wrapper>
    </Flex>
  );
};

export default FaqsPage;
