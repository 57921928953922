import * as React from "react";
import { forwardRef } from "react";

interface IFormat {
  allowedFormats: string;
  allowedMimeTypes: string;
}

interface IUploader {
  multiple?: boolean;
  formats: string;
  accept: string;
  handleFile: (arg: FileList, format: IFormat) => void;
}

const Uploader = forwardRef<HTMLInputElement, IUploader>(
  ({ multiple, formats, accept, handleFile }, ref) => {
    const onChangeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
      handleFile(e.target.files!, {
        allowedFormats: formats,
        allowedMimeTypes: accept,
      });
    };

    return (
      <input
        type="file"
        multiple={multiple}
        accept={accept}
        style={{ display: "none" }}
        ref={ref}
        onChange={onChangeHandler}
      />
    );
  },
);

export default Uploader;
