import React, { FC } from "react";
import { Text } from "@chakra-ui/react";

interface IToggle {
  onClickHandler: () => void;
}

const Toggle: FC<IToggle> = ({ children, onClickHandler }) => (
  <Text
    as="span"
    onClick={onClickHandler}
    display={{ base: "block", md: "none" }}
    position="absolute"
    top="0"
    right="0"
    cursor="pointer"
  >
    {children}
  </Text>
);

export default Toggle;
