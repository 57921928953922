import { getAuthHeader } from "utils";
import {
  IApiCallGetAllAppointmentsByPageRequest,
  IApiCallGetSingleAppointmentRequestPayload,
  IApiCallSetSingleAppointmentSubmittedRequestPayload,
} from "./types";
import { ROLES } from "constants/index";

export async function getAllAppointmentsByPage({
  page,
  token,
}: IApiCallGetAllAppointmentsByPageRequest) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ENDPOINT}/doctors/videocalls?page=${page}`,
      {
        headers: getAuthHeader(token),
      },
    ).then((res) => {
      if (!res.ok) {
        throw res;
      }

      return res.json();
    });

    return {
      appointments: response.vcalls,
      pageCount: response.number_of_pages,
    };
  } catch (e) {
    console.log("getAllAppointmentsByPage:", e);
    throw e;
  }
}

export async function setSingleAppointmentSubmitted({
  consultationId,
  token,
}: IApiCallSetSingleAppointmentSubmittedRequestPayload) {
  try {
    await fetch(
      `${process.env.REACT_APP_ENDPOINT}/doctors/videocalls/${consultationId}/submit`,
      {
        method: "PUT",
        headers: getAuthHeader(token),
      },
    ).then((res) => {
      if (!res.ok) {
        throw res;
      }
      return res.json();
    });
  } catch (e) {
    console.log("setSingleAppointmentSubmitted:", e);
    throw e;
  }
}

export async function getSingleAppointment({
  consultationId,
  role,
  token,
}: IApiCallGetSingleAppointmentRequestPayload) {
  try {
    const roleUrlString = role === ROLES.doctor ? "doctors" : "patients";

    const response = await fetch(
      `${process.env.REACT_APP_ENDPOINT}/${roleUrlString}/videocalls/${consultationId}`,
      {
        headers: getAuthHeader(token),
      },
    ).then((res) => {
      if (!res.ok) {
        throw res;
      }
      return res.json();
    });

    if (response.status === "expired") {
      throw new Error("Document not found");
    }

    if (
      window.location.pathname.includes("show") &&
      response.status === "enabled"
    ) {
      throw new Error("Document not found");
    }

    return {
      attachments: response.attachments,
      updatedAt: response.updated_at,
      consultationExecutedAt: response.executed_at,
    };
  } catch (e) {
    console.log("getSingleAppointment:", e);
    throw e;
  }
}
