import { PayloadAction } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { takeLatest, call, put, select } from "redux-saga/effects";
import { reduxToken, reduxExpiresAt, reduxRole } from "redux/auth/selectors";
import { setMessage } from "redux/messages/actions";
import {
  getAllAppointmentsByPageRequest,
  getAllAppointmentsByPageSuccess,
  getAllAppointmentsByPageFailed,
  setSingleAppointmentSubmittedRequest,
  setSingleAppointmentSubmittedFailed,
  getSingleAppointmentRequest,
  getSingleAppointmentSuccess,
  getSingleAppointmentFailed,
} from "./actions";
import { getAllAttachmentsSuccess } from "redux/attachments/actions";
import { resetAuth } from "redux/auth/actions";
import {
  IGetAllAppointmentsByPageRequestPayload,
  ISetSingleAppointmentSubmittedRequestPayload,
  IGetSingleAppointmentRequestPayload,
} from "./types";
import * as AppointmentsApi from "./apiCall";
import { isExpired, clearLocalStorage, getLoginUrl, checkError } from "utils";

function* getAllAppointmentsByPageSaga({
  payload,
}: PayloadAction<IGetAllAppointmentsByPageRequestPayload>) {
  const { page } = payload;
  const token = yield select(reduxToken);
  const expiresAt = yield select(reduxExpiresAt);
  const loginUrl = getLoginUrl();

  try {
    const {
      appointments,
      pageCount,
    } = yield call(AppointmentsApi.getAllAppointmentsByPage, { page, token });
    yield put(getAllAppointmentsByPageSuccess({ appointments, pageCount }));
  } catch (e) {
    yield checkError(e);
    if (isExpired(expiresAt)) {
      yield put(getAllAppointmentsByPageFailed());
      yield put(resetAuth());
      clearLocalStorage();
      window.location.href = loginUrl;
    } else {
      yield put(
        setMessage({
          title: "Attenzione",
          type: "error",
          description:
            "Si è verificato un errore durante il caricamento dei videoconsulti.",
        }),
      );
      yield put(getAllAppointmentsByPageFailed());
    }
  }
}

function* setSingleAppointmentSubmittedSaga({
  payload,
}: PayloadAction<ISetSingleAppointmentSubmittedRequestPayload>) {
  const { consultationId } = payload;
  const role = yield select(reduxRole);
  const token = yield select(reduxToken);
  const expiresAt = yield select(reduxExpiresAt);
  const h24 = 86400000;
  const isoNow = new Date().toISOString();
  const now = new Date(isoNow).getTime();
  const loginUrl = getLoginUrl();

  try {
    const {
      attachments,
      updatedAt: isoUpdatedAt,
      consultationExecutedAt,
    } = yield call(AppointmentsApi.getSingleAppointment, {
      consultationId,
      role,
      token,
    });
    const updatedAt = new Date(isoUpdatedAt).getTime();

    // If we see that "now - updated_at" is bigger than 24h, we show error and update attachments accordingly
    if (now - updatedAt >= h24) {
      yield put(
        setMessage({
          title: "Attenzione",
          type: "error",
          description:
            "Alcuni documenti sono scaduti, pertanto occorre caricarli nuovamente.",
        }),
      );
      yield put(
        getAllAttachmentsSuccess({ attachments, consultationExecutedAt }),
      );
      return;
    }

    yield call(AppointmentsApi.setSingleAppointmentSubmitted, {
      ...payload,
      token,
    });
    yield put(push("/"));
    yield put(
      setMessage({
        title: "Congratulazioni",
        type: "success",
        description:
          "L'invio dei documenti è stato eseguito con successo. Entro pochi minuti tu e il paziente riceverete l'email.",
      }),
    );
  } catch (e) {
    yield checkError(e);
    if (isExpired(expiresAt)) {
      yield put(setSingleAppointmentSubmittedFailed());
      yield put(resetAuth());
      clearLocalStorage();
      window.location.href = loginUrl;
    } else {
      yield put(
        setMessage({
          title: "Attenzione",
          type: "error",
          description:
            "Si è verificato un errore durante l'invio dei documenti.",
        }),
      );
      yield put(setSingleAppointmentSubmittedFailed());
    }
  }
}

function* getSingleAppointmentSaga({
  payload,
}: PayloadAction<IGetSingleAppointmentRequestPayload>) {
  const { consultationId } = payload;
  const role = yield select(reduxRole);
  const token = yield select(reduxToken);

  try {
    const { attachments, updatedAt, consultationExecutedAt } = yield call(
      AppointmentsApi.getSingleAppointment,
      {
        consultationId,
        role,
        token,
      },
    );
    yield put(getSingleAppointmentSuccess({ updatedAt }));
    yield put(
      getAllAttachmentsSuccess({ attachments, consultationExecutedAt }),
    );
  } catch (e) {
    yield checkError(e);
    yield put(
      setMessage({
        title: "Attenzione",
        type: "error",
        description:
          "Si è verificato un errore durante il caricamento del videoconsulto.",
      }),
    );
    yield put(getSingleAppointmentFailed());
  }
}

export default function* appointmentsSaga() {
  yield takeLatest(
    getAllAppointmentsByPageRequest.type,
    getAllAppointmentsByPageSaga,
  );
  yield takeLatest(
    setSingleAppointmentSubmittedRequest.type,
    setSingleAppointmentSubmittedSaga,
  );
  yield takeLatest(getSingleAppointmentRequest.type, getSingleAppointmentSaga);
}
