import * as React from "react";
import { FC, useRef, useCallback } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, useToast } from "@chakra-ui/react";
import { ArrowUpIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";
import imageCompression from "browser-image-compression";
import Wrapper from "components/Wrapper";
import FormSection from "components/Form/FormSection/styled";
import FormHeader from "components/Form/FormHeader";
import FormInput from "components/Form/FormInput";
import FormTextarea from "components/Form/FormTextarea";
import Uploader from "components/Uploader";
import {
  updateUserInfoRequest,
  uploadSignatureRequest,
} from "redux/auth/actions";
import {
  reduxDenomination,
  reduxAddress,
  reduxDocumentHeader,
  reduxVat,
  reduxTaxCode,
  reduxIsSubmitting,
} from "redux/auth/selectors";
import { palette } from "theme/theme";
import { SIGNATURE_LIMIT } from "constants/index";

interface IFormData {
  denomination: string;
  address: string;
  "custom:document_header"?: string;
  "custom:vat_number": string;
  "custom:tax_code": string;
}

interface IFormat {
  allowedFormats: string;
  allowedMimeTypes: string;
}

interface ILocation {
  from: string | undefined;
}

const ProfilePage: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation<ILocation>();
  const denomination = useSelector(reduxDenomination);
  const address = useSelector(reduxAddress);
  const documentHeader = useSelector(reduxDocumentHeader);
  const vat = useSelector(reduxVat);
  const taxCode = useSelector(reduxTaxCode);
  const isSubmitting = useSelector(reduxIsSubmitting);
  const toast = useToast();
  const hiddenFileInput = useRef<HTMLInputElement>(null!);
  const SIGNATURE_ACTIVE = process.env.REACT_APP_SIGNATURE_ACTIVE;

  const getDefaultValues = useCallback(
    (): IFormData => ({
      denomination: denomination || "",
      address: address || "",
      "custom:document_header": documentHeader || "",
      "custom:vat_number": vat || "",
      "custom:tax_code": taxCode || "",
    }),
    [address, denomination, taxCode, vat, documentHeader],
  );

  const { handleSubmit, errors, register } = useForm<IFormData>({
    reValidateMode: "onBlur",
    shouldFocusError: false,
    defaultValues: getDefaultValues(),
  });

  const validateField = useCallback(
    (value) => {
      if (!value) {
        toast({
          title: "Attenzione",
          description: "Compila i campi obbligatori",
          position: "bottom",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        return false;
      } else {
        return true;
      }
    },
    [toast],
  );

  const validateVatNumber = useCallback(
    (value) => {
      if (value.length !== 0 && value.length !== 11) {
        toast({
          title: "Attenzione",
          description:
            "Se presente, il campo 'P. IVA' deve essere lungo 11 caratteri.",
          position: "bottom",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        return false;
      } else {
        return true;
      }
    },
    [toast],
  );

  const validateTaxCode = useCallback(
    (value) => {
      if (value.length !== 0 && value.length !== 16) {
        toast({
          title: "Attenzione",
          description:
            "Se presente, il campo 'Codice Fiscale' deve essere lungo 16 caratteri.",
          position: "bottom",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        return false;
      } else {
        return true;
      }
    },
    [toast],
  );

  const validateDocumentHeader = useCallback(
    (value) => {
      if (value.length > 1000) {
        toast({
          title: "Attenzione",
          description:
            "Se presente, il campo 'Intestazione documenti' non può superare 1000 caratteri.",
          position: "bottom",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
        return false;
      } else {
        return true;
      }
    },
    [toast],
  );

  // If we landed here from Create/Edit page, send info to saga: this way, we can redirect to the Create/Edit right after updating user info
  const onSubmit = useCallback(
    (data) =>
      dispatch(
        updateUserInfoRequest({ data, redirectTo: location.state?.from }),
      ),
    [dispatch, location.state?.from],
  );

  const handleFile = useCallback(
    async (
      incomingFile: FileList,
      { allowedFormats, allowedMimeTypes }: IFormat,
    ) => {
      const file = incomingFile[0];

      const arrayAllowed: Array<string> = allowedMimeTypes
        .split(",")
        .map((str) => str.trim());

      // Check size and format before starting resize
      if (file.size > SIGNATURE_LIMIT) {
        toast.closeAll();
        toast({
          title: "Attenzione",
          description:
            "Il file supera la dimensione massima consentita (10 Mb). Si prega di selezionare nuovamente il file.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (!arrayAllowed.includes(file.type)) {
        toast({
          title: "Attenzione",
          description: `Il file non è stato accettato. Carica il file in uno dei formati supportati: ${allowedFormats}.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      try {
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 10,
          maxWidthOrHeight: 1200,
          useWebWorker: false,
        });

        const signature = new File([compressedFile], file.name, {
          type: file.type,
        });

        dispatch(uploadSignatureRequest({ signature }));
      } catch (e) {
        console.log("handleFile:", e);
        toast({
          title: "Attenzione",
          description:
            "Si è verificato un errore durante il caricamento del file. Si prega di riprovare.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [dispatch, toast],
  );

  const handleLogout = useCallback(() => {
    return <Redirect to="/logout" />;
  }, []);

  return (
    <Box bg={palette.LIGHT_BLUE}>
      <Wrapper>
        <Box
          p="50px"
          bg={palette.WHITE}
          borderRadius="30px"
          w="100%"
          color={palette.DARK_GRAY}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormSection>
              <FormHeader title="Dati intestazione da inserire nei moduli" />

              <FormInput
                id="denomination"
                label="Denominazione"
                required
                ref={register({ validate: validateField })}
                isInvalid={!!errors.denomination}
              />
              <FormTextarea
                id="custom:document_header"
                label="Intestazione documenti"
                ref={register({ validate: validateDocumentHeader })}
                isInvalid={!!errors["custom:document_header"]}
              />
            </FormSection>

            <FormSection>
              <FormHeader title="Altri dati necessari per fatturazione" />

              <FormInput
                id="address"
                label="Indirizzo"
                required
                ref={register({ validate: validateField })}
                isInvalid={!!errors.address}
              />
              <FormInput
                id="custom:vat_number"
                label="P. IVA"
                required
                ref={register({ validate: validateVatNumber })}
                isInvalid={!!errors["custom:vat_number"]}
              />
              <FormInput
                id="custom:tax_code"
                label="Codice Fiscale (facoltativo)"
                ref={register({ validate: validateTaxCode })}
                isInvalid={!!errors["custom:tax_code"]}
              />

              <Button
                type="submit"
                w="100%"
                colorScheme="myOrange"
                isLoading={isSubmitting}
                onClick={handleSubmit(onSubmit)}
              >
                Aggiorna
              </Button>
            </FormSection>

            {SIGNATURE_ACTIVE === "true" ? (
              <FormSection>
                <FormHeader
                  title="Inserisci l'immagine della tua firma"
                  subtitle="Carica l'immagine della tua firma"
                />

                <Button
                  w="100%"
                  leftIcon={<ArrowUpIcon />}
                  colorScheme="myOrange"
                  disabled={false}
                  onClick={() => hiddenFileInput.current.click()}
                >
                  Carica
                </Button>

                <Uploader
                  multiple={false}
                  formats=".png, .jpeg, .jpg"
                  accept="image/png, image/jpeg"
                  handleFile={handleFile}
                  ref={hiddenFileInput}
                />
              </FormSection>
            ) : null}

            <FormSection>
              <Link to="/logout">
                <Button w="100%" colorScheme="myBlue" onClick={handleLogout}>
                  Logout
                </Button>
              </Link>
            </FormSection>
          </form>
        </Box>
      </Wrapper>
    </Box>
  );
};

export default ProfilePage;
