import * as React from "react";
import { forwardRef } from "react";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import { palette } from "theme/theme";
import FormInputWrapper from "components/Form/FormInputWrapper/styled";

interface IFormInput {
  id: string;
  label: string;
  required?: boolean;
  isInvalid: boolean;
}

const FormInput = forwardRef<HTMLInputElement, IFormInput>(
  ({ id, label, required, isInvalid }, ref) => (
    <FormInputWrapper>
      <FormControl>
        <FormLabel htmlFor={id}>
          {required ? `${label}*:` : `${label}:`}
        </FormLabel>
        <Input
          borderColor={palette.DARK_GRAY}
          focusBorderColor={palette.DARK_GRAY}
          _hover={{ borderColor: palette.DARK_GRAY }}
          type="text"
          id={id}
          name={id}
          px="10px"
          isInvalid={isInvalid}
          ref={ref}
        />
      </FormControl>
    </FormInputWrapper>
  ),
);

export default FormInput;
