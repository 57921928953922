import { createAction } from "@reduxjs/toolkit";
import {
  IGetAllAttachmentsRequestPayload,
  IGetAllAttachmentsSuccessPayload,
  IDownloadSingleAttachmentRequestPayload,
  IDeleteSingleAttachmentRequestPayload,
  IDeleteSingleAttachmentSuccessPayload,
  IGetSingleAttachmentRequestPayload,
  IGetSingleAttachmentSuccessPayload,
  ISetCheckedLanguages,
  IAddAttachmentRequestPayload,
  IUpdateAttachmentRequestPayload,
  IGetLanguagesSuccessPayload,
  IUploadRequest,
  IUploadSuccess,
} from "./types";

export const getAllAttachmentsRequest = createAction<IGetAllAttachmentsRequestPayload>(
  "GET_ALL_ATTACHMENTS_REQUEST",
);
export const getAllAttachmentsSuccess = createAction<IGetAllAttachmentsSuccessPayload>(
  "GET_ALL_ATTACHMENTS_SUCCESS",
);
export const getAllAttachmentsFailed = createAction(
  "GET_ALL_ATTACHMENTS_FAILED",
);

export const downloadSingleAttachmentRequest = createAction<IDownloadSingleAttachmentRequestPayload>(
  "DOWNLOAD_SINGLE_ATTACHMENT_REQUEST",
);
export const downloadSingleAttachmentFailed = createAction(
  "DOWNLOAD_SINGLE_ATTACHMENT_FAILED",
);

export const deleteSingleAttachmentRequest = createAction<IDeleteSingleAttachmentRequestPayload>(
  "DELETE_SINGLE_ATTACHMENT_REQUEST",
);
export const deleteSingleAttachmentSuccess = createAction<IDeleteSingleAttachmentSuccessPayload>(
  "DELETE_SINGLE_ATTACHMENT_SUCCESS",
);
export const deleteSingleAttachmentFailed = createAction(
  "DELETE_SINGLE_ATTACHMENT_FAILED",
);

export const getSingleAttachmentRequest = createAction<IGetSingleAttachmentRequestPayload>(
  "GET_SINGLE_ATTACHMENT_REQUEST",
);
export const getSingleAttachmentSuccess = createAction<IGetSingleAttachmentSuccessPayload>(
  "GET_SINGLE_ATTACHMENT_SUCCESS",
);
export const getSingleAttachmentFailed = createAction(
  "GET_SINGLE_ATTACHMENT_FAILED",
);

export const setCheckedLanguages = createAction<ISetCheckedLanguages>(
  "SET_CHECKED_LANGUAGES",
);

export const addAttachmentRequest = createAction<IAddAttachmentRequestPayload>(
  "ADD_ATTACHMENT_REQUEST",
);
export const addAttachmentSuccess = createAction("ADD_ATTACHMENT_SUCCESS");
export const addAttachmentFailed = createAction("ADD_ATTACHMENT_FAILED");

export const updateAttachmentRequest = createAction<IUpdateAttachmentRequestPayload>(
  "UPDATE_ATTACHMENT_REQUEST",
);
export const updateAttachmentSuccess = createAction(
  "UPDATE_ATTACHMENT_SUCCESS",
);
export const updateAttachmentFailed = createAction("UPDATE_ATTACHMENT_FAILED");

export const getLanguagesRequest = createAction("GET_LANGUAGES_REQUEST");
export const getLanguagesSuccess = createAction<IGetLanguagesSuccessPayload>(
  "GET_LANGUAGES_SUCCESS",
);
export const getLanguagesFailed = createAction("GET_LANGUAGES_FAILED");

export const uploadRequest = createAction<IUploadRequest>("UPLOAD_REQUEST");
export const uploadSuccess = createAction<IUploadSuccess>("UPLOAD_SUCCESS");
export const uploadFailed = createAction("UPLOAD_FAILED");

export const cleanActiveAttachment = createAction("CLEAN_ACTIVE_ATTACHMENT");

export const resetAttachments = createAction("RESET_ATTACHMENTS");
