import { createSelector } from "@reduxjs/toolkit";
// Fix for circular dependencies error
// import { RootState } from "../store";

const tokenSelector = (state) => state.auth.token;
const isAuthenticatedSelector = (state) => state.auth.isAuthenticated;
const expiresAtSelector = (state) => state.auth.expiresAt;
const renewAtSelector = (state) => state.auth.renewAt;
const roleSelector = (state) => state.auth.role;
const userSelector = (state) => state.auth.user;
const addressSelector = (state) =>
  state.auth.user.cognitoUserAttributes.address;
const documentHeaderSelector = (state) =>
  state.auth.user.cognitoUserAttributes["custom:document_header"];
const taxCodeSelector = (state) =>
  state.auth.user.cognitoUserAttributes["custom:tax_code"];
const vatSelector = (state) =>
  state.auth.user.cognitoUserAttributes["custom:vat_number"];
const phoneNumberSelector = (state) =>
  state.auth.user.cognitoUserAttributes.phone_number;
const denominationSelector = (state) =>
  `${state.auth.user.firstName} ${state.auth.user.lastName}`;
const isSubmittingSelector = (state) => state.auth.isSubmitting;
const hasSignatureSelector = (state) => state.auth.hasSignature;
const isCheckingSignatureSelector = (state) => state.auth.isCheckingSignature;

export const reduxToken = createSelector(tokenSelector, (token) => token);

export const reduxIsAuthenticated = createSelector(
  isAuthenticatedSelector,
  (isAuthenticated) => isAuthenticated,
);

export const reduxExpiresAt = createSelector(
  expiresAtSelector,
  (expiresAt) => expiresAt,
);

export const reduxRenewAt = createSelector(
  renewAtSelector,
  (renewAt) => renewAt,
);

export const reduxRole = createSelector(roleSelector, (role) => role);

export const reduxUser = createSelector(userSelector, (user) => user);

export const reduxAddress = createSelector(
  addressSelector,
  (address) => address,
);

export const reduxDocumentHeader = createSelector(
  documentHeaderSelector,
  (document_header) => document_header,
);

export const reduxTaxCode = createSelector(
  taxCodeSelector,
  (tax_code) => tax_code,
);

export const reduxVat = createSelector(vatSelector, (vat_number) => vat_number);

export const reduxPhoneNumber = createSelector(
  phoneNumberSelector,
  (phone_number) => phone_number,
);

export const reduxDenomination = createSelector(
  denominationSelector,
  (denomination) => denomination,
);

export const reduxIsSubmitting = createSelector(
  isSubmittingSelector,
  (isSubmitting) => isSubmitting,
);

export const reduxHasSignature = createSelector(
  hasSignatureSelector,
  (hasSignature) => hasSignature,
);

export const reduxIsCheckingSignature = createSelector(
  isCheckingSignatureSelector,
  (isCheckingSignature) => isCheckingSignature,
);
