import * as React from "react";
import { FC } from "react";
import { Box } from "@chakra-ui/react";
import { palette } from "theme/theme";

const Divider: FC = () => (
  <Box
    w="80%"
    m="0 auto"
    borderBottom={`2px dashed ${palette.BLUE}`}
    mb="30px"
  />
);

export default Divider;
