import { Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { palette } from "theme/theme";

const CardWrapper = styled(Flex)`
  background-color: transparent;
  border: 1px solid ${palette.GRAY};
  color: ${palette.DARK_GRAY};
  padding: 20px;
  border-radius: 15px;

  & + & {
    margin-top: 20px;
  }
`;

export default CardWrapper;
