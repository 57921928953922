import * as React from "react";
import { FormControl, FormLabel, Textarea, forwardRef } from "@chakra-ui/react";
import { palette } from "theme/theme";
import FormInputWrapper from "components/Form/FormInputWrapper/styled";

interface IFormTextarea {
  id: string;
  label: string;
  required?: boolean;
  isInvalid: boolean;
  ref: React.Ref<any>;
}

const FormTextarea = forwardRef<IFormTextarea, "div">(
  ({ id, label, required, isInvalid }, ref) => (
    <FormInputWrapper>
      <FormControl>
        <FormLabel htmlFor={id}>
          {required ? `${label}*:` : `${label}:`}
        </FormLabel>
        <Textarea
          borderColor={palette.DARK_GRAY}
          focusBorderColor={palette.DARK_GRAY}
          _hover={{ borderColor: palette.DARK_GRAY }}
          _focus={{ borderColor: palette.DARK_GRAY }}
          id={id}
          name={id}
          px="10px"
          isInvalid={isInvalid}
          ref={ref}
        />
      </FormControl>
    </FormInputWrapper>
  ),
);

export default FormTextarea;
