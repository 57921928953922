import * as React from "react";
import { FC } from "react";
import { Box, Spinner } from "@chakra-ui/react";

interface ISpinner {
  visible: boolean;
}

const SpinnerComponent: FC<ISpinner> = ({ visible }) => (
  <Box
    position="fixed"
    width="100%"
    height="100%"
    background="rgba(0, 0, 0, .5)"
    zIndex={1000}
    display={visible ? "block" : "none"}
  >
    <Spinner
      thickness="4px"
      speed="0.65s"
      emptyColor="gray.200"
      color="blue.500"
      display="block"
      position="fixed"
      top="50%"
      left="50%"
      width="40px"
      height="40px"
      marginTop="-20px"
      marginLeft="-20px"
    />
  </Box>
);

export default SpinnerComponent;
