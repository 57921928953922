import appointments from "./appointments/reducers";
import messages from "./messages/reducers";
import auth from "./auth/reducers";
import attachments from "./attachments/reducers";
import spinner from "./spinner/reducers";
import patient from "./patient/reducers";

const reducers = {
  appointments,
  messages,
  auth,
  attachments,
  spinner,
  patient,
};

export default reducers;
