import { all, call, spawn } from "redux-saga/effects";

import appointmentsRootSaga from "redux/appointments/sagas";
import authRootSaga from "redux/auth/sagas";
import attachmentsRootSaga from "redux/attachments/sagas";
import patientRootSaga from "redux/patient/sagas";

function* rootSaga() {
  const sagas = [
    appointmentsRootSaga,
    authRootSaga,
    attachmentsRootSaga,
    patientRootSaga,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.error(e);
          }
        }
      }),
    ),
  );
}

export default rootSaga;
