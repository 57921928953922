import * as React from "react";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { addAttachmentRequest } from "redux/attachments/actions";
import FormDocument from "components/FormDocument";

const CreatePage: FC = () => {
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (data) => dispatch(addAttachmentRequest(data)),
    [dispatch],
  );

  return <FormDocument mode="Create" onSubmit={handleSubmit} />;
};

export default CreatePage;
