import * as React from "react";
import { FC, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Box, Text, Skeleton } from "@chakra-ui/react";
import { palette } from "theme/theme";
import Card from "components/Card";
import CardSection from "components/Card/CardSection/styled";
import Pagination from "components/Pagination";
import { IAppointment } from "components/Card/types";
import { reduxDenomination } from "redux/auth/selectors";
import {
  getAllAppointmentsByPageRequest,
  setCurrentPage,
} from "redux/appointments/actions";
import {
  reduxAllAppointments,
  reduxIsLoadingAppointments,
} from "redux/appointments/selectors";

const Homepage: FC = () => {
  const dispatch = useDispatch();
  const user = useSelector(reduxDenomination);
  const appointments = useSelector(reduxAllAppointments);
  const loadingAppointments = useSelector(reduxIsLoadingAppointments);

  const fetchAppointments = useCallback(() => {
    dispatch(getAllAppointmentsByPageRequest({ page: 1 }));
  }, [dispatch]);

  useEffect(() => {
    fetchAppointments();
  }, [fetchAppointments]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentPage({ currentPage: 1 }));
    };
  }, [dispatch]);

  const renderAppointments = useCallback(() => {
    if (loadingAppointments) {
      return (
        <Skeleton
          startColor={palette.GRAY}
          endColor={palette.DARKER_GRAY}
          isLoaded={!loadingAppointments}
          h="24px"
        ></Skeleton>
      );
    }

    if (appointments?.length === 0 && !loadingAppointments) {
      return (
        <Text fontSize="xl" color={palette.DARK_GRAY}>
          Non hai ancora effettuato videoconsulti, siamo certi che presto
          riceverai richieste di prenotazione dai nostri utenti. Se vuoi sapere
          come raggiungere più pazienti, scrivici a{" "}
          <a href="mailto:customercare@pazienti.it">customercare@pazienti.it</a>
          .
        </Text>
      );
    }

    return appointments?.map((el: IAppointment) => (
      <Card key={el.id} appointment={el} />
    ));
  }, [appointments, loadingAppointments]);

  return (
    <>
      <Box py="4" bg={palette.LIGHT_BLUE}>
        <Box mx="auto" maxW="1200px" width="100%">
          <Box maxW="900px" px={{ base: "20px", md: "40px" }}>
            <Text fontSize="2xl" fontWeight="bold" color={palette.BLUE}>
              Ciao {user}
            </Text>
            <Text fontSize="xl" color={palette.DARK_GRAY}>
              In questa sezione puoi consultare l’elenco dei videoconsulti
              effettuati e inviare documenti ai pazienti.
            </Text>
          </Box>
        </Box>
      </Box>

      <Box py="30px">
        <Box mx="auto" maxW="1200px" width="100%">
          <CardSection px={{ base: "20px", md: "40px" }}>
            <Flex w="100%" justify="space-between" align="center">
              <Text
                fontSize="2xl"
                fontWeight="bold"
                textAlign={{ base: "center", md: "left" }}
                mb="10px"
                color={palette.BLUE}
              >
                Videoconsulti effettuati
              </Text>
            </Flex>
            {renderAppointments()}
            {appointments?.length > 0 ? (
              <Flex w="100%" justify="flex-end" align="center">
                <Pagination />
              </Flex>
            ) : null}
          </CardSection>
        </Box>
      </Box>
    </>
  );
};

export default Homepage;
