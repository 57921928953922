import { getAuthHeader } from "utils";
import {
  IGetUserRequestPayload,
  IGetNewTokenRequestPayload,
  IGetIdentityRequestPayload,
  IApiCallUpdateUserInfoRequestPayload,
  IApiCallGetPresignedPostData,
  IApiCallGetSignature,
  IApiCallDeleteSsoSession,
} from "./types";

function cacheBust(paramName = "ms") {
  return `?${paramName}=` + Date.now();
}

export async function getUser({ token }: IGetUserRequestPayload) {
  try {
    const response = await fetch(process.env.REACT_APP_OAUTH_USER_ENDPOINT, {
      headers: getAuthHeader(token),
    }).then((res) => {
      if (!res.ok) {
        throw res;
      }

      return res.json();
    });

    return response;
  } catch (e) {
    console.log("getUser:", e);
    throw e;
  }
}

export async function getIdentity({ token }: IGetIdentityRequestPayload) {
  try {
    const response = await fetch(
      process.env.REACT_APP_OAUTH_IDENTITY_ENDPOINT + cacheBust(),
      {
        headers: getAuthHeader(token),
      },
    ).then((res) => {
      if (!res.ok) {
        throw res;
      }

      return res.json();
    });

    return response;
  } catch (e) {
    console.log("getIdentity:", e);
    throw e;
  }
}

export async function getNewToken({ token }: IGetNewTokenRequestPayload) {
  try {
    const response = await fetch(process.env.REACT_APP_OAUTH_TOKEN_ENDPOINT, {
      headers: getAuthHeader(token),
    }).then((res) => {
      if (!res.ok) {
        throw res;
      }

      return res.json();
    });

    return response.access_token;
  } catch (e) {
    console.log("getNewToken:", e);
    throw e;
  }
}

export async function updateUserInfo({
  token,
  data,
  user,
}: IApiCallUpdateUserInfoRequestPayload) {
  try {
    const { address } = data;
    let { denomination } = data;
    const customDocumentHeader = data["custom:document_header"];
    const customTaxCode = data["custom:tax_code"];
    const customVatNumber = data["custom:vat_number"];

    denomination = denomination.trim();
    let [given_name, ...rest] = denomination.split(" ");
    let family_name = rest.join(" ");

    const body = JSON.stringify({
      ...user,
      firstName: given_name,
      lastName: family_name,
      cognitoUserAttributes: {
        ...user.cognitoUserAttributes,
        address,
        "custom:document_header": customDocumentHeader,
        "custom:tax_code": customTaxCode,
        "custom:vat_number": customVatNumber,
        family_name,
        given_name,
      },
    });

    await fetch(process.env.REACT_APP_OAUTH_USER_ENDPOINT, {
      headers: {
        ...getAuthHeader(token),
        "Content-Type": "application/json",
      },
      method: "PUT",
      body,
    }).then((res) => {
      if (!res.ok) {
        throw res;
      }
    });

    return JSON.parse(body);
  } catch (e) {
    console.log("updateUserInfo:", e);
    throw e;
  }
}

export async function getPresignedPostData({
  body,
  token,
}: IApiCallGetPresignedPostData) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ENDPOINT}/doctors/signatures`,
      {
        method: "POST",
        headers: {
          ...getAuthHeader(token),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      },
    ).then((res) => {
      if (!res.ok) {
        throw res;
      }

      return res.json();
    });

    return response;
  } catch (e) {
    console.log("getPresignedPostData:", e);
    throw e;
  }
}

export async function uploadSignature({ presignedPostData, formData }) {
  try {
    const response = await fetch(presignedPostData.url, {
      method: "POST",
      body: formData,
    }).then((res) => {
      if (!res.ok || res.status !== 201) {
        throw res;
      }

      const uuid = presignedPostData.url_fields.key.split("/")[1];

      return {
        uuid,
        type: "FileAttachment",
      };
    });

    return response;
  } catch (e) {
    console.log("uploadFile:", e);
    throw e;
  }
}

export async function getSignature({ token }: IApiCallGetSignature) {
  try {
    const { url } = await fetch(
      `${process.env.REACT_APP_ENDPOINT}/doctors/signatures`,
      {
        headers: getAuthHeader(token),
      },
    ).then((res) => {
      if (!res.ok) {
        throw res;
      }

      return res.json();
    });

    return url;
  } catch (e) {
    console.log("getSignature:", e);
    throw e;
  }
}

export async function deleteSsoSession({ token }: IApiCallDeleteSsoSession) {
  try {
    await fetch(`${process.env.REACT_APP_OAUTH_SSO_ENDPOINT}`, {
      method: "DELETE",
      headers: getAuthHeader(token),
      credentials: "include",
    });
    return;
  } catch (e) {
    console.log("deleteSsoSession:", e);
  }
}
