import { Flex } from "@chakra-ui/react";
import styled from "@emotion/styled";

const FileThumbWrapper = styled(Flex)`
  & + & {
    margin-top: 40px;
  }
`;

export default FileThumbWrapper;
