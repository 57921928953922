import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

// base, sm, md, lg, xl
const breakpoints = createBreakpoints({
  sm: "360px",
  md: "768px",
  lg: "1024px",
  xl: "1366px",
});

export const palette = {
  BLACK: "#000",
  WHITE: "#fff",
  WET_ASPHALT: "#34495e",

  BLUE_METAL: "#90aec2",
  LIGHT_BLUE: "#ebf2f2",
  BLUE: "#4f7fa3",
  DARKER_BLUE: "#436b8a",

  LIGHT_GRAY: "#eeeeed",
  LIGHTER_GRAY: "#f5f5f4",
  MIDDLE_GRAY: "#cfcfcf",
  GRAY: "#b7b7b6",
  DARKER_GRAY: "#9b9b9a",
  DARK_GRAY: "#665e59",

  ORANGE: "#e48e36",
  DARKER_ORANGE: "#d3781c",

  GREEN: "#7bb36e",
  DARKER_GREEN: "#62a154",

  YELLOW: "#f4efae",

  RED: "#e53e3e",
  DARKER_RED: "#d91d1d",

  AZURE: "#99cdd8",
};

const customTheme = extendTheme({
  breakpoints,
  colors: {
    myOrange: {
      500: palette.ORANGE,
      600: palette.DARKER_ORANGE,
    },
    myBlue: {
      500: palette.BLUE,
      600: palette.DARKER_BLUE,
    },
    myGray: {
      500: palette.GRAY,
      600: palette.DARKER_GRAY,
    },
    myGreen: {
      500: palette.GREEN,
      600: palette.DARKER_GREEN,
    },
    myRed: {
      500: palette.RED,
      600: palette.DARKER_RED,
    },
    myTransparent: {
      500: "transparent",
      600: "transparent",
    },
  },
  fonts: {
    heading: "'Nunito Sans', sans-serif",
    body: "'Nunito Sans', sans-serif",
    mono: "Menlo, monospace",
    content: "Source Serif Pro, sans-serif",
  },
  fontWeights: {
    thin: 200,
    light: 300,
    normal: 400,
    semiBold: 600,
    extraBold: 800,
  },
  fontSizes: {
    xs: "0.75rem", // 12px
    sm: "0.875rem", // 14px
    md: "1rem", // 16px
    lg: "1.125rem", // 18px
    xl: "1.25rem", // 20px
    "2xl": "1.5rem", // 24px
    "3xl": "1.875rem", // 30px
    "4xl": "2.25rem", // 36px
    "5xl": "3rem", // 48px
    "6xl": "4rem", // 64px
  },
  shadows: {
    sm: `none`,
    md: `none`,
    lg: `none`,
    xl: `none`,
    "2xl": `none`,
    outline: `0px 1px 0px ${palette.WET_ASPHALT}`,
    inner: `none`,
    none: `none`,
    insetAndOutset: `inset 0px 4px 2px -2px ${palette.MIDDLE_GRAY}, 0px 4px 2px -2px ${palette.MIDDLE_GRAY}`,
    prescription: "10px 10px 15px 0px rgba(0, 0, 0, 0.3)",
  },
});

export default customTheme;
