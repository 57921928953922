import { createReducer } from "@reduxjs/toolkit";
import {
  getPatientInfoRequest,
  getPatientInfoSuccess,
  getPatientInfoFailed,
  resetPatient,
} from "./actions";

interface IPatient {
  fullName: string;
  address: string;
  fiscalCode: string;
}

export interface IPatientState {
  currentPatient: IPatient;
  loading: boolean;
}

const initialState: IPatientState = {
  currentPatient: {
    fullName: "",
    address: "",
    fiscalCode: "",
  },
  loading: false,
};

const patientReducer = createReducer(initialState, {
  [getPatientInfoRequest.type]: (state) => {
    state.loading = true;
  },
  [getPatientInfoSuccess.type]: (state, action) => {
    state.loading = false;
    state.currentPatient = action.payload;
  },
  [getPatientInfoFailed.type]: (state) => {
    state.loading = false;
    state.currentPatient = initialState.currentPatient;
  },
  [resetPatient.type]: (state) => {
    state.loading = initialState.loading;
    state.currentPatient = initialState.currentPatient;
  },
});

export default patientReducer;
