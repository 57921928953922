import { createReducer } from "@reduxjs/toolkit";
import {
  getAllAttachmentsRequest,
  getAllAttachmentsSuccess,
  getAllAttachmentsFailed,
  deleteSingleAttachmentRequest,
  deleteSingleAttachmentSuccess,
  deleteSingleAttachmentFailed,
  getSingleAttachmentRequest,
  getSingleAttachmentSuccess,
  getSingleAttachmentFailed,
  setCheckedLanguages,
  addAttachmentRequest,
  addAttachmentSuccess,
  addAttachmentFailed,
  getLanguagesRequest,
  getLanguagesSuccess,
  getLanguagesFailed,
  updateAttachmentRequest,
  updateAttachmentSuccess,
  updateAttachmentFailed,
  uploadRequest,
  uploadSuccess,
  uploadFailed,
  cleanActiveAttachment,
  resetAttachments,
} from "./actions";
import { ITextAttachment, IFileAttachment, ICheckedLanguage } from "./types";

export interface IAttachmentsState {
  all: Array<ITextAttachment | IFileAttachment>;
  loading: boolean;
  consultationExecutedAt: string | undefined;
  active: {
    attachmentText: string;
    checkedLanguages: Array<ICheckedLanguage>;
  };
  loadingLanguages: boolean;
  uploading: boolean;
}

const initialState: IAttachmentsState = {
  all: [],
  loading: false,
  consultationExecutedAt: undefined,
  active: {
    attachmentText: "",
    checkedLanguages: [],
  },
  loadingLanguages: false,
  uploading: false,
};

const attachmentsReducer = createReducer(initialState, {
  [getAllAttachmentsRequest.type]: (state) => {
    state.loading = true;
    state.all = initialState.all;
    state.consultationExecutedAt = initialState.consultationExecutedAt;
  },
  [getAllAttachmentsSuccess.type]: (state, action) => {
    state.loading = false;
    state.all = action.payload.attachments;
    state.consultationExecutedAt = action.payload.consultationExecutedAt;
  },
  [getAllAttachmentsFailed.type]: (state) => {
    state.loading = false;
  },
  [deleteSingleAttachmentRequest.type]: (state) => {
    state.loading = true;
  },
  [deleteSingleAttachmentSuccess.type]: (state, action) => {
    state.loading = false;
    state.all = state.all.filter((el) => el.uuid !== action.payload.uuid);
  },
  [deleteSingleAttachmentFailed.type]: (state) => {
    state.loading = false;
  },
  [getSingleAttachmentRequest.type]: (state) => {
    state.loading = true;
    state.loadingLanguages = true;
  },
  [getSingleAttachmentSuccess.type]: (state, action) => {
    state.loading = false;
    state.loadingLanguages = false;
    state.active.attachmentText = action.payload.attachment.text;
    state.active.checkedLanguages = action.payload.languages;
  },
  [getSingleAttachmentFailed.type]: (state) => {
    state.loading = false;
    state.loadingLanguages = false;
  },
  [setCheckedLanguages.type]: (state, action) => {
    state.active.checkedLanguages = action.payload.languages;
  },
  [addAttachmentRequest.type]: (state) => {
    state.loading = true;
  },
  [addAttachmentSuccess.type]: (state) => {
    state.loading = false;
  },
  [addAttachmentFailed.type]: (state) => {
    state.loading = false;
  },
  [getLanguagesRequest.type]: (state) => {
    state.loadingLanguages = true;
  },
  [getLanguagesSuccess.type]: (state, action) => {
    state.loadingLanguages = false;
    state.active.checkedLanguages = action.payload.languages;
  },
  [getLanguagesFailed.type]: (state) => {
    state.loadingLanguages = false;
  },
  [updateAttachmentRequest.type]: (state) => {
    state.loading = true;
  },
  [updateAttachmentSuccess.type]: (state) => {
    state.loading = false;
  },
  [updateAttachmentFailed.type]: (state) => {
    state.loading = false;
  },
  [uploadRequest.type]: (state) => {
    state.uploading = true;
  },
  [uploadSuccess.type]: (state, action) => {
    state.uploading = false;
    state.all = [...state.all, ...action.payload.attachments];
  },
  [uploadFailed.type]: (state) => {
    state.uploading = false;
  },
  [cleanActiveAttachment.type]: (state) => {
    state.active = initialState.active;
  },
  [resetAttachments.type]: (state) => {
    state.all = initialState.all;
    state.loading = initialState.loading;
    state.consultationExecutedAt = initialState.consultationExecutedAt;
    state.active = initialState.active;
    state.loadingLanguages = initialState.loadingLanguages;
    state.uploading = initialState.uploading;
  },
});

export default attachmentsReducer;
