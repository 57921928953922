import * as React from "react";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import { logoutRequest } from "redux/auth/actions";
import Wrapper from "components/Wrapper";
import { palette } from "theme/theme";

const LogoutPage: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logoutRequest());
  }, [dispatch]);

  return (
    <Flex flex="1" bg={palette.LIGHT_BLUE}>
      <Wrapper>
        <Spinner color={palette.BLUE} />
        <Text color={palette.BLUE}>Logout in corso...</Text>
      </Wrapper>
    </Flex>
  );
};

export default LogoutPage;
