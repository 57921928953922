import * as React from "react";
import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Box, Text } from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons";
import { palette } from "theme/theme";
import {
  getAllAppointmentsByPageRequest,
  setCurrentPage,
} from "redux/appointments/actions";
import {
  reduxCurrentPage,
  reduxPageCount,
  reduxIsLoadingAppointments,
} from "redux/appointments/selectors";

const Pagination: FC = () => {
  const dispatch = useDispatch();
  const currentPage = useSelector(reduxCurrentPage);
  const pageCount = useSelector(reduxPageCount);
  const isLoadingAppointments = useSelector(reduxIsLoadingAppointments);

  const hasNextPage = pageCount > currentPage;
  const hasPreviousPage = currentPage !== 1 && pageCount !== 1;

  const handlePageChange = useCallback(
    (action) => {
      let newCurrentPage;

      if (action === "next") {
        newCurrentPage = currentPage + 1;
        dispatch(getAllAppointmentsByPageRequest({ page: newCurrentPage }));
        dispatch(setCurrentPage({ currentPage: newCurrentPage }));
      } else if (action === "previous") {
        newCurrentPage = currentPage - 1;
        dispatch(getAllAppointmentsByPageRequest({ page: newCurrentPage }));
        dispatch(setCurrentPage({ currentPage: newCurrentPage }));
      }
    },
    [dispatch, currentPage],
  );

  if (isLoadingAppointments) {
    return null;
  }

  return (
    <Flex align="center" mt="20px">
      {hasPreviousPage && (
        <Box
          w="60px"
          h="30px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          border={`1px solid ${palette.DARKER_GRAY}`}
          mr="10px"
          cursor="pointer"
          onClick={() => handlePageChange("previous")}
        >
          <ArrowBackIcon boxSize="28px" color={palette.DARKER_GRAY} />
        </Box>
      )}
      <Text as="span" color={palette.DARKER_GRAY}>
        Pagina {currentPage} di {pageCount}
      </Text>
      {hasNextPage && (
        <Box
          w="60px"
          h="30px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          border={`1px solid ${palette.DARKER_GRAY}`}
          ml="10px"
          cursor="pointer"
          onClick={() => handlePageChange("next")}
        >
          <ArrowForwardIcon boxSize="28px" color={palette.DARKER_GRAY} />
        </Box>
      )}
    </Flex>
  );
};

export default Pagination;
