import { PayloadAction } from "@reduxjs/toolkit";
import { takeLatest, call, put, select } from "redux-saga/effects";
import { reduxToken, reduxRole } from "redux/auth/selectors";
import { setMessage } from "redux/messages/actions";
import {
  getPatientInfoRequest,
  getPatientInfoSuccess,
  getPatientInfoFailed,
} from "./actions";
import { IGetPatientInfoRequestPayload } from "./types";
import * as PatientApi from "./apiCall";
import { checkError } from "utils";

function* getPatientInfoSaga({
  payload,
}: PayloadAction<IGetPatientInfoRequestPayload>) {
  const { consultationId } = payload;
  const role = yield select(reduxRole);
  const token = yield select(reduxToken);

  try {
    const { fullName, address, fiscalCode } = yield call(
      PatientApi.getPatientInfo,
      {
        consultationId,
        role,
        token,
      },
    );

    yield put(getPatientInfoSuccess({ fullName, address, fiscalCode }));
  } catch (e) {
    yield checkError(e);
    yield put(
      setMessage({
        title: "Attenzione",
        type: "error",
        description:
          "Si è verificato un errore durante il caricamento dei dati del paziente.",
      }),
    );
    yield put(getPatientInfoFailed());
  }
}

export default function* appointmentsSaga() {
  yield takeLatest(getPatientInfoRequest.type, getPatientInfoSaga);
}
