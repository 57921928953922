import * as React from "react";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateAttachmentRequest } from "redux/attachments/actions";
import FormDocument from "components/FormDocument";

const EditPage: FC = () => {
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    (data) => dispatch(updateAttachmentRequest(data)),
    [dispatch],
  );

  return <FormDocument mode="Edit" onSubmit={handleSubmit} />;
};

export default EditPage;
