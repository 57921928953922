import { createAction } from "@reduxjs/toolkit";
import {
  IGetUserRequestPayload,
  IGetUserSuccessPayload,
  IGetNewTokenSuccessPayload,
  IGetRoleSuccessPayload,
  IUpdateUserInfoRequestPayload,
  IUpdateUserInfoSuccessPayload,
  IUploadSignatureRequest,
  IGetSignatureSuccessPayload,
} from "./types";

export const getUserRequest = createAction<IGetUserRequestPayload>(
  "GET_USER_REQUEST",
);
export const getUserSuccess = createAction<IGetUserSuccessPayload>(
  "GET_USER_SUCCESS",
);
export const getUserFailed = createAction("GET_USER_FAILED");

export const getNewTokenRequest = createAction("GET_NEW_TOKEN_REQUEST");
export const getNewTokenSuccess = createAction<IGetNewTokenSuccessPayload>(
  "GET_NEW_TOKEN_SUCCESS",
);
export const getNewTokenFailed = createAction("GET_NEW_TOKEN_FAILED");

export const getRoleSuccess = createAction<IGetRoleSuccessPayload>(
  "GET_ROLE_SUCCESS",
);
export const getRoleFailed = createAction("GET_ROLE_FAILED");

export const refreshAuthRequest = createAction("REFRESH_AUTH_REQUEST");

export const logoutRequest = createAction("LOGOUT_REQUEST");
export const resetAuth = createAction("RESET_AUTH");

export const updateUserInfoRequest = createAction<IUpdateUserInfoRequestPayload>(
  "UPDATE_USER_INFO_REQUEST",
);
export const updateUserInfoSuccess = createAction<IUpdateUserInfoSuccessPayload>(
  "UPDATE_USER_INFO_SUCCESS",
);
export const updateUserInfoFailed = createAction("UPDATE_USER_INFO_FAILED");

export const uploadSignatureRequest = createAction<IUploadSignatureRequest>(
  "UPLOAD_SIGNATURE_REQUEST",
);
export const uploadSignatureSuccess = createAction("UPLOAD_SIGNATURE_SUCCESS");
export const uploadSignatureFailed = createAction("UPLOAD_SIGNATURE_FAILED");

export const getSignatureRequest = createAction("GET_SIGNATURE_REQUST");
export const getSignatureSuccess = createAction<IGetSignatureSuccessPayload>(
  "GET_SIGNATURE_SUCCESS",
);
export const getSignatureFailed = createAction("GET_SIGNATURE_FAILED");
