import { PayloadAction } from "@reduxjs/toolkit";
import { push } from "connected-react-router";
import { takeLatest, call, put, all, select } from "redux-saga/effects";
import { differenceInMilliseconds, parseISO } from "date-fns";
import { setMessage } from "redux/messages/actions";
import { resetAuth } from "redux/auth/actions";
import { reduxExpiresAt, reduxToken, reduxRole } from "redux/auth/selectors";
import {
  getAllAttachmentsRequest,
  getAllAttachmentsSuccess,
  getAllAttachmentsFailed,
  deleteSingleAttachmentRequest,
  deleteSingleAttachmentSuccess,
  deleteSingleAttachmentFailed,
  getSingleAttachmentRequest,
  getSingleAttachmentSuccess,
  getSingleAttachmentFailed,
  addAttachmentRequest,
  addAttachmentSuccess,
  addAttachmentFailed,
  updateAttachmentRequest,
  updateAttachmentSuccess,
  updateAttachmentFailed,
  getLanguagesRequest,
  getLanguagesSuccess,
  getLanguagesFailed,
  uploadRequest,
  uploadSuccess,
  uploadFailed,
  downloadSingleAttachmentRequest,
  downloadSingleAttachmentFailed,
} from "./actions";
import {
  IGetAllAttachmentsRequestPayload,
  IDeleteSingleAttachmentRequestPayload,
  IGetSingleAttachmentRequestPayload,
  IAddAttachmentRequestPayload,
  IUpdateAttachmentRequestPayload,
  IUploadRequest,
  IDownloadSingleAttachmentRequestPayload,
} from "./types";
import * as AttachmentsApi from "./apiCall";
import * as AppointmentsApi from "redux/appointments/apiCall";
import { isExpired, clearLocalStorage, getLoginUrl, checkError } from "utils";

function* getAllAttachmentsSaga({
  payload,
}: PayloadAction<IGetAllAttachmentsRequestPayload>) {
  try {
    const token = yield select(reduxToken);

    const {
      attachments,
      consultationExecutedAt,
    } = yield call(AttachmentsApi.getAllAttachments, { ...payload, token });
    yield put(
      getAllAttachmentsSuccess({ attachments, consultationExecutedAt }),
    );
  } catch (e) {
    yield checkError(e);
    yield put(
      setMessage({
        title: "Attenzione",
        type: "error",
        description:
          "Si è verificato un errore durante il caricamento dei documenti.",
      }),
    );
    yield put(getAllAttachmentsFailed());
  }
}

function* deleteSingleAttachmentSaga({
  payload,
}: PayloadAction<IDeleteSingleAttachmentRequestPayload>) {
  const token = yield select(reduxToken);
  const expiresAt = yield select(reduxExpiresAt);
  const loginUrl = getLoginUrl();

  try {
    const deletedUuid = yield call(AttachmentsApi.deleteSingleAttachment, {
      ...payload,
      token,
    });
    yield put(deleteSingleAttachmentSuccess({ uuid: deletedUuid }));
    yield put(
      setMessage({
        title: "Congratulazioni",
        type: "success",
        description: "Il documento è stato eliminato con successo.",
      }),
    );
  } catch (e) {
    yield checkError(e);
    if (isExpired(expiresAt)) {
      yield put(deleteSingleAttachmentFailed());
      yield put(resetAuth());
      clearLocalStorage();
      window.location.href = loginUrl;
    } else {
      yield put(
        setMessage({
          title: "Attenzione",
          type: "error",
          description: `Si è verificato un errore durante l'eliminazione del file.`,
        }),
      );
      yield put(deleteSingleAttachmentFailed());
    }
  }
}

function* getSingleAttachmentSaga({
  payload,
}: PayloadAction<IGetSingleAttachmentRequestPayload>) {
  try {
    const token = yield select(reduxToken);

    const [attachment, orderedLanguages] = yield all([
      call(AttachmentsApi.getSingleAttachment, { ...payload, token }),
      call(AttachmentsApi.getLanguages),
    ]);

    // Set attachment languages
    const checkedLanguages = orderedLanguages.map((language) =>
      attachment.languages.includes(language.code)
        ? { ...language, checked: true }
        : { ...language, checked: false },
    );

    // Put "checked: true" first
    const checkedAndOrderedLanguages = checkedLanguages.sort(
      (a, b) => b.checked - a.checked,
    );

    yield put(
      getSingleAttachmentSuccess({
        attachment,
        languages: checkedAndOrderedLanguages,
      }),
    );
  } catch (e) {
    yield checkError(e);
    yield put(push("/"));
    yield put(
      setMessage({
        title: "Attenzione",
        type: "error",
        description: `Si è verificato un errore durante il caricamento del documento.`,
      }),
    );
    yield put(getSingleAttachmentFailed());
  }
}

function* addAttachmentSaga({
  payload,
}: PayloadAction<IAddAttachmentRequestPayload>) {
  const token = yield select(reduxToken);
  const expiresAt = yield select(reduxExpiresAt);
  const loginUrl = getLoginUrl();

  try {
    yield call(AttachmentsApi.addAttachment, { ...payload, token });

    yield put(addAttachmentSuccess());
    yield put(push(`/consultation/${payload.consultationId}/submit`));

    yield put(
      setMessage({
        title: "Congratulazioni",
        type: "success",
        description: "Il documento è stato creato con successo.",
      }),
    );
  } catch (e) {
    yield checkError(e);
    if (isExpired(expiresAt)) {
      yield put(addAttachmentFailed());
      yield put(resetAuth());
      clearLocalStorage();
      window.location.href = loginUrl;
    } else {
      yield put(
        setMessage({
          title: "Attenzione",
          type: "error",
          description:
            "Si è verificato un errore durante la creazione del documento.",
        }),
      );
      yield put(addAttachmentFailed());
    }
  }
}

function* updateAttachmentSaga({
  payload,
}: PayloadAction<IUpdateAttachmentRequestPayload>) {
  const token = yield select(reduxToken);
  const expiresAt = yield select(reduxExpiresAt);
  const loginUrl = getLoginUrl();

  try {
    yield call(AttachmentsApi.updateAttachment, { ...payload, token });

    yield put(updateAttachmentSuccess());
    yield put(push(`/consultation/${payload.consultationId}/submit`));

    yield put(
      setMessage({
        title: "Congratulazioni",
        type: "success",
        description: "Il documento è stato modificato con successo.",
      }),
    );
  } catch (e) {
    yield checkError(e);
    if (isExpired(expiresAt)) {
      yield put(updateAttachmentFailed());
      yield put(resetAuth());
      clearLocalStorage();
      window.location.href = loginUrl;
    } else {
      yield put(
        setMessage({
          title: "Attenzione",
          type: "error",
          description:
            "Si è verificato un errore durante la modifica del documento.",
        }),
      );
      yield put(updateAttachmentFailed());
    }
  }
}

function* startFileUpload(file, consultationId, kind) {
  const token = yield select(reduxToken);
  const body = {
    file_ext: file.type.split("/")[1],
    kind,
  };

  const presignedPostData = yield call(AttachmentsApi.getPresignedPostData, {
    body,
    consultationId,
    token,
  });

  const formData = new FormData();
  Object.keys(presignedPostData.url_fields).forEach((key) => {
    formData.append(key, presignedPostData.url_fields[key]);
  });
  formData.append("file", file);

  const result = yield call(AttachmentsApi.uploadFile, {
    presignedPostData,
    formData,
    kind,
  });

  return result;
}

function* uploadSaga({ payload }: PayloadAction<IUploadRequest>) {
  const role = yield select(reduxRole);
  const token = yield select(reduxToken);
  const expiresAt = yield select(reduxExpiresAt);
  const { files, consultationId, documentType } = payload;
  const h24 = 86400000;
  const now = new Date();
  const loginUrl = getLoginUrl();

  try {
    // Fetch updatedAt value from BE before uploading
    const {
      attachments: prevAttachments,
      updatedAt,
      consultationExecutedAt,
    } = yield call(AppointmentsApi.getSingleAppointment, {
      consultationId,
      role,
      token,
    });
    const timePastLastUpdate = differenceInMilliseconds(
      now,
      parseISO(updatedAt),
    );

    // If time past last update is bigger than 24h, we show error and update attachments accordingly
    if (timePastLastUpdate >= h24) {
      yield put(
        setMessage({
          title: "Attenzione",
          type: "error",
          description:
            "Alcuni documenti sono scaduti, pertanto occorre caricarli nuovamente.",
        }),
      );
      yield put(
        getAllAttachmentsSuccess({
          attachments: prevAttachments,
          consultationExecutedAt,
        }),
      );
      yield put(uploadFailed());
      return;
    }

    const promises = files.map((file) =>
      startFileUpload(file, consultationId, documentType),
    );
    const attachments = yield all(promises);

    yield put(
      setMessage({
        title: "Congratulazioni",
        type: "success",
        description: "Caricamento dei documenti eseguito con successo.",
      }),
    );
    yield put(uploadSuccess({ attachments }));
  } catch (e) {
    yield checkError(e);
    if (isExpired(expiresAt)) {
      yield put(uploadFailed());
      yield put(resetAuth());
      clearLocalStorage();
      window.location.href = loginUrl;
    } else {
      yield put(
        setMessage({
          title: "Attenzione",
          type: "error",
          description:
            "Si è verificato un errore durante l'upload dei documenti.",
        }),
      );
      yield put(uploadFailed());
    }
  }
}

function* getLanguagesSaga() {
  try {
    const languages = yield call(AttachmentsApi.getLanguages);
    const checkedLanguages = languages.map((language) => ({
      ...language,
      checked: false,
    }));
    yield put(getLanguagesSuccess({ languages: checkedLanguages }));
  } catch (e) {
    yield checkError(e);
    yield put(
      setMessage({
        title: "Attenzione",
        type: "error",
        description:
          "Si è verificato un errore durante il caricamento delle lingue.",
      }),
    );
    yield put(getLanguagesFailed());
  }
}

function* downloadSingleAttachmentSaga({
  payload,
}: PayloadAction<IDownloadSingleAttachmentRequestPayload>) {
  const expiresAt = yield select(reduxExpiresAt);
  const role = yield select(reduxRole);
  const token = yield select(reduxToken);
  const loginUrl = getLoginUrl();

  try {
    const url = yield call(AttachmentsApi.getDownloadLink, {
      ...payload,
      role,
      token,
    });
    window.open(url, "_blank");
  } catch (e) {
    yield checkError(e);
    if (isExpired(expiresAt)) {
      yield put(downloadSingleAttachmentFailed());
      yield put(resetAuth());
      clearLocalStorage();
      window.location.href = loginUrl;
    } else {
      yield put(
        setMessage({
          title: "Attenzione",
          type: "error",
          description:
            "Si è verificato un errore durante il download del file.",
        }),
      );
      yield put(downloadSingleAttachmentFailed());
    }
  }
}

export default function* attachmentsSaga() {
  yield takeLatest(getAllAttachmentsRequest.type, getAllAttachmentsSaga);
  yield takeLatest(
    deleteSingleAttachmentRequest.type,
    deleteSingleAttachmentSaga,
  );
  yield takeLatest(getSingleAttachmentRequest.type, getSingleAttachmentSaga);
  yield takeLatest(addAttachmentRequest.type, addAttachmentSaga);
  yield takeLatest(updateAttachmentRequest.type, updateAttachmentSaga);
  yield takeLatest(getLanguagesRequest.type, getLanguagesSaga);
  yield takeLatest(uploadRequest.type, uploadSaga);
  yield takeLatest(
    downloadSingleAttachmentRequest.type,
    downloadSingleAttachmentSaga,
  );
}
