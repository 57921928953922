import { createReducer } from "@reduxjs/toolkit";
import { setMessage, resetMessage } from "./actions";

export interface IMessageState {
  title: string | undefined;
  type?: "success" | "error" | "warning" | "info" | undefined;
  description: string | undefined;
}

const initialState: IMessageState = {
  title: undefined,
  type: undefined,
  description: undefined,
};

const messagesReducer = createReducer(initialState, {
  [setMessage.type]: (state, action) => {
    state.title = action.payload.title;
    state.type = action.payload.type;
    state.description = action.payload.description;
  },
  [resetMessage.type]: (state) => {
    state.title = initialState.title;
    state.type = initialState.type;
    state.description = initialState.description;
  },
});

export default messagesReducer;
