import { createReducer } from "@reduxjs/toolkit";
import {
  uploadSignatureRequest,
  uploadSignatureSuccess,
  uploadSignatureFailed,
} from "redux/auth/actions";
import {
  uploadRequest,
  uploadSuccess,
  uploadFailed,
} from "redux/attachments/actions";

export interface ISpinnerState {
  visible: boolean;
}

const initialState: ISpinnerState = {
  visible: false,
};

const spinnerReducer = createReducer(initialState, {
  [uploadSignatureRequest.type]: (state) => {
    state.visible = true;
  },
  [uploadSignatureSuccess.type]: (state) => {
    state.visible = false;
  },
  [uploadSignatureFailed.type]: (state) => {
    state.visible = false;
  },
  [uploadRequest.type]: (state) => {
    state.visible = true;
  },
  [uploadSuccess.type]: (state) => {
    state.visible = false;
  },
  [uploadFailed.type]: (state) => {
    state.visible = false;
  },
});

export default spinnerReducer;
