import * as React from "react";
import { FC } from "react";
import { Flex } from "@chakra-ui/react";

const Wrapper: FC = ({ children }) => (
  <Flex
    direction="column"
    justify="center"
    align="center"
    px={{ base: "20px", md: "40px" }}
    py={{ base: "15px", md: "40px" }}
    maxW="900px"
    m="0 auto"
    w="100%"
  >
    {children}
  </Flex>
);

export default Wrapper;
