import * as React from "react";
import { FC, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Text, Box, Button, Skeleton, Spinner } from "@chakra-ui/react";
import Wrapper from "components/Wrapper";
import FileThumb from "components/FileThumb";
import Divider from "components/Divider";
import theme, { palette } from "theme/theme";
import {
  getSingleAppointmentRequest,
  resetAppointments,
} from "redux/appointments/actions";
import {
  downloadSingleAttachmentRequest,
  resetAttachments,
} from "redux/attachments/actions";
import { reduxUser, reduxRole } from "redux/auth/selectors";
import { reduxIsLoadingAppointments } from "redux/appointments/selectors";
import {
  reduxAllAttachments,
  reduxConsultationExecutedAt,
} from "redux/attachments/selectors";
import { getPatientInfoRequest } from "redux/patient/actions";
import { reduxPatient, reduxIsLoadingPatient } from "redux/patient/selectors";
import { getDateAndTime } from "utils";
import { ROLES } from "constants/index";

interface IParams {
  consultationId: string;
}

const ShowPage: FC = () => {
  const dispatch = useDispatch();
  const attachments = useSelector(reduxAllAttachments);
  const userData = useSelector(reduxUser);
  const { firstName, lastName } = userData!;
  const role = useSelector(reduxRole);
  const consultationExecutedAt = useSelector(reduxConsultationExecutedAt);
  const isLoadingAppointments = useSelector(reduxIsLoadingAppointments);
  const isLoadingPatient = useSelector(reduxIsLoadingPatient);

  const { fullName: patientFullName } = useSelector(reduxPatient);

  const { consultationId } = useParams<IParams>();
  const [date] = getDateAndTime(consultationExecutedAt);

  useEffect(() => {
    dispatch(getPatientInfoRequest({ consultationId }));
    dispatch(getSingleAppointmentRequest({ consultationId }));
  }, [dispatch, consultationId]);

  useEffect(() => {
    return () => {
      dispatch(resetAppointments());
      dispatch(resetAttachments());
    };
  }, [dispatch]);

  const handleDownload = useCallback(
    (uuid, languageCode) =>
      dispatch(
        downloadSingleAttachmentRequest({ consultationId, uuid, languageCode }),
      ),
    [dispatch, consultationId],
  );

  const renderGreetings = useCallback(() => {
    return (
      <>
        <Text fontSize="3xl" fontWeight="bold" color={palette.DARK_GRAY}>
          Ciao {`${firstName} ${lastName}`}
        </Text>
        {role === ROLES.member ? (
          <a href={process.env.REACT_APP_BACK_TO_HOME_ENDPOINT}>
            <Button
              size="lg"
              px="20px"
              colorScheme="myOrange"
              fontWeight="bold"
              maxW="100%"
            >
              Prenota un videoconsulto
            </Button>
          </a>
        ) : null}
      </>
    );
  }, [firstName, lastName, role]);

  const renderAttachments = useCallback(() => {
    if (isLoadingAppointments || isLoadingPatient) {
      return (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="lg"
          m="0 auto"
        />
      );
    }

    if (attachments?.length === 0 && !isLoadingAppointments) {
      return (
        <Text fontSize="xl" textAlign="center">
          Non è stato caricato alcun documento
        </Text>
      );
    }

    if (attachments?.length > 0 && !isLoadingPatient) {
      return attachments?.map((attachment) => (
        <FileThumb
          key={attachment?.uuid}
          documentId={attachment?.uuid}
          consultationId={consultationId}
          consultationDate={consultationExecutedAt}
          patientName={patientFullName}
          type={attachment?.type}
          kind={attachment?.kind}
          handleDownload={(languageCode) =>
            handleDownload(attachment?.uuid, languageCode)
          }
          languages={attachment.languages}
          page="Show"
        />
      ));
    }
  }, [
    attachments,
    consultationExecutedAt,
    consultationId,
    handleDownload,
    patientFullName,
    isLoadingPatient,
    isLoadingAppointments,
  ]);

  return (
    <>
      <Box
        py="6"
        bg={palette.LIGHTER_GRAY}
        boxShadow={theme.shadows.insetAndOutset}
        zIndex={100}
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          align="center"
          justify={{ base: "center", md: "space-between" }}
          mx="auto"
          px={{ base: "20px", md: "40px" }}
          maxW="1200px"
        >
          {renderGreetings()}
        </Flex>
      </Box>

      <Flex flex="1" direction="column" bg={palette.LIGHT_BLUE} py="20px">
        <Wrapper>
          <Skeleton
            startColor={palette.GRAY}
            endColor={palette.DARKER_GRAY}
            isLoaded={!isLoadingAppointments}
          >
            <Text fontSize="2xl" textAlign="center" color={palette.BLUE}>
              Documenti inerenti al videoconsulto effettuato in data {date}:
            </Text>
          </Skeleton>
          <Text
            textAlign="center"
            mb="10px"
            fontStyle="italic"
            fontWeight="bold"
            color={palette.BLUE}
          >
            Ricorda: i documenti verranno cancellati tra 7 giorni.
          </Text>
          <Divider />

          <Flex direction="column" w="60%">
            {renderAttachments()}
          </Flex>
        </Wrapper>
      </Flex>
    </>
  );
};

export default ShowPage;
