import { createAction } from "@reduxjs/toolkit";
import {
  IGetAllAppointmentsByPageRequestPayload,
  IGetAllAppointmentsByPageSuccessPayload,
  ISetSingleAppointmentSubmittedRequestPayload,
  IGetSingleAppointmentRequestPayload,
  IGetSingleAppointmentSuccessPayload,
  ISetCurrentPage,
} from "./types";

export const getAllAppointmentsByPageRequest = createAction<IGetAllAppointmentsByPageRequestPayload>(
  "GET_ALL_APPOINTMENTS_BY_PAGE_REQUEST",
);
export const getAllAppointmentsByPageSuccess = createAction<IGetAllAppointmentsByPageSuccessPayload>(
  "GET_ALL_APPOINTMENTS_BY_PAGE_SUCCESS",
);
export const getAllAppointmentsByPageFailed = createAction(
  "GET_ALL_APPOINTMENTS_BY_PAGE_FAILED",
);

export const setSingleAppointmentSubmittedRequest = createAction<ISetSingleAppointmentSubmittedRequestPayload>(
  "SET_SINGLE_APPOINTMENT_SUBMITTED_REQUEST",
);
export const setSingleAppointmentSubmittedFailed = createAction(
  "SET_SINGLE_APPOINTMENT_SUBMITTED_FAILED",
);

export const getSingleAppointmentRequest = createAction<IGetSingleAppointmentRequestPayload>(
  "GET_SINGLE_APPOINTMENT_REQUEST",
);
export const getSingleAppointmentSuccess = createAction<IGetSingleAppointmentSuccessPayload>(
  "GET_SINGLE_APPOINTMENT_SUCCESS",
);
export const getSingleAppointmentFailed = createAction(
  "GET_SINGLE_APPOINTMENT_FAILED",
);

export const setCurrentPage = createAction<ISetCurrentPage>("SET_CURRENT_PAGE");

export const resetAppointments = createAction("RESET_APPOINTMENTS");
