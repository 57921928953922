import { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isAfter } from "date-fns";
import {
  refreshAuthRequest,
  getNewTokenRequest,
  logoutRequest,
} from "redux/auth/actions";
import {
  reduxIsAuthenticated,
  reduxExpiresAt,
  reduxRenewAt,
  reduxRole,
} from "redux/auth/selectors";

export const useAuth = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(reduxIsAuthenticated);
  const expiresAt = useSelector(reduxExpiresAt);
  const renewAt = useSelector(reduxRenewAt);
  const role = useSelector(reduxRole);
  const timeoutRef = useRef<number>(null!);

  const delay = Number(process.env.REACT_APP_REFRESH_TOKEN_TIME);

  const refreshAuth = useCallback(() => {
    if (isAuthenticated) {
      dispatch(refreshAuthRequest());
    }
  }, [dispatch, isAuthenticated]);

  const checkTokenExpiration = useCallback(() => {
    if (isAuthenticated && expiresAt) {
      if (isAfter(new Date(), expiresAt)) {
        dispatch(logoutRequest());
      }
    }
  }, [dispatch, expiresAt, isAuthenticated]);

  const checkTokenRenewal = useCallback(() => {
    timeoutRef.current = window.setTimeout(() => {
      if (isAuthenticated && renewAt) {
        if (isAfter(new Date(), renewAt)) {
          dispatch(getNewTokenRequest());
        }
      }
    }, delay);
  }, [dispatch, isAuthenticated, renewAt, delay]);

  return {
    isAuthenticated,
    timeoutRef,
    refreshAuth,
    checkTokenExpiration,
    checkTokenRenewal,
    role,
  };
};
