import * as React from "react";
import { FC } from "react";
import { Box, Text } from "@chakra-ui/react";

interface IFormHeader {
  title: string;
  subtitle?: string;
}

const FormHeader: FC<IFormHeader> = ({ title, subtitle = "" }) => (
  <Box textAlign="center" mb="20px">
    <Text fontSize="3xl" fontWeight="bold">
      {title}
    </Text>

    {subtitle.length > 0 && <Text fontSize="xl">{subtitle}</Text>}
  </Box>
);

export default FormHeader;
