import * as React from "react";
import { FC, useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Flex, Box, Image, Text } from "@chakra-ui/react";
import { palette } from "theme/theme";
import FileThumbWrapper from "components/FileThumb/FileThumbWrapper/styled";
import DeleteImage from "assets/delete.png";
import EditImage from "assets/edit.png";
import DownloadImage from "assets/download.png";
import { translate, getDateAndTime, getImageAndDocumentKind } from "utils";

interface ILanguage {
  code: string;
  name: string;
}

interface IFileThumb {
  documentId: string;
  consultationId: string;
  consultationDate: string | undefined;
  patientName: string;
  type: "TextAttachment" | "FileAttachment";
  kind: "recipe" | "report" | "other";
  handleDelete?: (e: React.MouseEvent<HTMLElement>) => void;
  handleDownload?: (languageCode: string) => void;
  languages: Array<string>;
  page: "Submit" | "Show";
}

const FileThumb: FC<IFileThumb> = ({
  documentId,
  consultationId,
  consultationDate,
  patientName,
  type,
  kind,
  handleDelete,
  handleDownload,
  languages,
  page,
}) => {
  const [translatedLanguages, setTranslatedLanguages] = useState<
    Array<ILanguage>
  >([]);

  const [image, documentType] = getImageAndDocumentKind(kind);
  const [date] = getDateAndTime(consultationDate);

  const initializeTranslatedLanguages = useCallback(async () => {
    const incomingLanguages = await translate(languages);
    setTranslatedLanguages(incomingLanguages);
  }, [languages]);

  useEffect(() => {
    initializeTranslatedLanguages();
  }, [initializeTranslatedLanguages]);

  const renderTranslatedLanguages = useCallback(() => {
    const languages = translatedLanguages.map((el) => el.name);
    return (
      <Text color={palette.BLUE} fontWeight="bold">
        Tradotto in {languages.join(", ")}
      </Text>
    );
  }, [translatedLanguages]);

  if (page !== "Show" && page !== "Submit") {
    return null;
  }

  if (page === "Show") {
    // Render "it" FileThumb, then iterate over languages
    return (
      <>
        <FileThumbWrapper
          justify="center"
          align="center"
          direction={{ base: "column", md: "row" }}
        >
          <Flex
            flex="1"
            align="center"
            justify="center"
            display={{ base: "none", md: "flex" }}
          >
            <Image src={image} boxSize="50px" />
          </Flex>
          <Flex
            flex="4"
            direction="column"
            color={palette.DARK_GRAY}
            ml={{ base: "0", md: "20px" }}
            textAlign={{ base: "center", md: "left" }}
          >
            <Text>{`${documentType} videoconsulto`}</Text>
            <Text fontWeight="bold">{`${patientName} - Videoconsulto del ${date}`}</Text>
          </Flex>
          <Flex
            flex="2"
            align="center"
            justify="flex-end"
            mt={{ base: "10px", md: "0px" }}
          >
            <Box onClick={() => handleDownload!("")}>
              <Image src={DownloadImage} boxSize="30px" cursor="pointer" />
            </Box>
          </Flex>
        </FileThumbWrapper>

        {translatedLanguages?.length > 0 &&
          translatedLanguages?.map((language) => (
            <FileThumbWrapper
              key={language.code}
              justify="center"
              align="center"
              direction={{ base: "column", md: "row" }}
            >
              <Flex
                flex="1"
                align="center"
                justify="center"
                display={{ base: "none", md: "flex" }}
              >
                <Image src={image} boxSize="50px" />
              </Flex>
              <Flex
                flex="4"
                direction="column"
                color={palette.DARK_GRAY}
                ml={{ base: "0", md: "20px" }}
                textAlign={{ base: "center", md: "left" }}
              >
                <Text fontWeight="bold" color={palette.BLUE}>
                  Tradotto in {language.name}
                </Text>
                <Text>{`${documentType} videoconsulto`}</Text>
                <Text fontWeight="bold">{`${patientName} - Videoconsulto del ${date}`}</Text>
              </Flex>
              <Flex
                flex="2"
                align="center"
                justify="flex-end"
                mt={{ base: "10px", md: "0px" }}
              >
                <Box onClick={() => handleDownload!(language.code)}>
                  <Image src={DownloadImage} boxSize="30px" cursor="pointer" />
                </Box>
              </Flex>
            </FileThumbWrapper>
          ))}
      </>
    );
  } else {
    return (
      <FileThumbWrapper
        justify="center"
        align="center"
        direction={{ base: "column", md: "row" }}
      >
        <Flex
          flex="1"
          align="center"
          justify="center"
          display={{ base: "none", md: "flex" }}
        >
          <Image src={image} boxSize="50px" />
        </Flex>
        <Flex
          flex="4"
          direction="column"
          color={palette.DARK_GRAY}
          ml={{ base: "0", md: "20px" }}
          textAlign={{ base: "center", md: "left" }}
        >
          <Text>{`${documentType} videoconsulto`}</Text>
          <Text fontWeight="bold">{`${patientName} - ${date}`}</Text>
          {translatedLanguages?.length > 0 ? renderTranslatedLanguages() : null}
        </Flex>
        <Flex
          flex="2"
          align="center"
          justify="flex-end"
          mt={{ base: "10px", md: "0px" }}
        >
          <Image
            src={DeleteImage}
            boxSize="30px"
            cursor="pointer"
            onClick={handleDelete}
          />
          {type === "TextAttachment" ? (
            <Box ml="20px">
              <Link
                to={`/consultation/${consultationId}/${kind}/${documentId}/write`}
              >
                <Image src={EditImage} boxSize="30px" cursor="pointer" />
              </Link>
            </Box>
          ) : null}
        </Flex>
      </FileThumbWrapper>
    );
  }
};

export default FileThumb;
