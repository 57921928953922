import * as React from "react";
import { FC } from "react";
import { Link } from "react-router-dom";
import { Flex, Text, Button, Box, Image } from "@chakra-ui/react";
import { palette } from "theme/theme";
import CardWrapper from "components/Card/CardWrapper/styled";
import ButtonsWrapper from "components/Card/ButtonsWrapper/styled";
import Calendar from "assets/calendar.png";
import { ICard } from "./types";
import { getDateAndTime } from "utils";

const Card: FC<ICard> = ({ appointment }) => {
  const [startDate, startTime] = getDateAndTime(appointment?.executed_at);

  return (
    <CardWrapper
      direction={{ base: "column", md: "row" }}
      justify={{ md: "space-between" }}
      align={{ base: "center" }}
    >
      <Flex direction="column" color={palette.GRAY}>
        <Text fontSize="xl" fontWeight="bold">
          {appointment?.full_name}
        </Text>
        <Text>
          {"Specialità: "}
          <Text as="span" fontWeight="bold">
            {appointment?.speciality_name}
          </Text>
        </Text>
        <Text>
          {"Videoconsulto da "}
          <Text as="span" fontWeight="bold">
            {appointment?.duration_in_minutes} minuti
          </Text>
        </Text>
        <Box display="flex" alignItems="center">
          <Image src={Calendar} boxSize="20px" display="inline-block" />
          <Text fontWeight="bold" ml="10px">
            <Text as="span">{startDate}</Text>
            <Text as="span" fontWeight="normal">
              {" ore "}
            </Text>
            <Text as="span">{startTime}</Text>
          </Text>
        </Box>
      </Flex>
      <ButtonsWrapper
        direction="column"
        mt={{ base: "20px", md: "0" }}
        maxW={{ base: "250px", md: "none" }}
        w={{ base: "100%", md: "auto" }}
      >
        {appointment.status === "enabled" ? (
          <Link to={`/consultation/${appointment.id}/submit`}>
            <Button
              size="xs"
              w="100%"
              px="35px"
              colorScheme="myOrange"
              fontWeight="bold"
            >
              Carica
            </Button>
          </Link>
        ) : (
          <Link to={`/consultation/${appointment.id}/show`}>
            <Button
              size="xs"
              w="100%"
              px="35px"
              colorScheme="myOrange"
              fontWeight="bold"
            >
              Visualizza
            </Button>
          </Link>
        )}
      </ButtonsWrapper>
    </CardWrapper>
  );
};

export default Card;
