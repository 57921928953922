import { put } from "redux-saga/effects";
import { logoutRequest } from "redux/auth/actions";
import { initialState } from "redux/auth/reducers";
import { createIntl, createIntlCache } from "@formatjs/intl";
import { shouldPolyfill as shouldPolyfillDisplayNames } from "@formatjs/intl-displaynames/should-polyfill";
import { shouldPolyfill as shouldPolyfillGetCanonicalLocales } from "@formatjs/intl-getcanonicallocales/should-polyfill";
import { shouldPolyfill as shouldPolyfillLocale } from "@formatjs/intl-locale/should-polyfill";
import { addMilliseconds, getTime } from "date-fns";
import {
  IMatch,
  ILocation,
  IFaq,
  IHydrateFromLocalStorage,
  ILanguage,
} from "./types";
import RecipeImage from "assets/prescription.png";
import ReportImage from "assets/medical_report.png";
import OtherImage from "assets/other.png";

export const isActivePage = (_match: IMatch, location: ILocation) => {
  if (!location) return false;
  const { pathname } = location;
  return pathname === "/";
};

export const getDateAndTime = (str: string | undefined) => {
  if (!str) return [];

  const moment = new Date(str);

  const date = moment.toLocaleDateString("it-IT", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const time = moment.toLocaleTimeString("it-IT", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });

  return [date, time];
};

export const splitArray = (yourArray: IFaq[]): Array<IFaq[]> => {
  let halfwayThrough = Math.ceil(yourArray.length / 2);
  let arrayFirstHalf = yourArray.slice(0, halfwayThrough);
  let arraySecondHalf = yourArray.slice(halfwayThrough, yourArray.length);

  return [arrayFirstHalf, arraySecondHalf];
};

export const getTokenFromUrl = () => {
  const searchString = window.location.hash?.substring(1);
  const accessToken = new URLSearchParams(searchString).get("access_token");

  return accessToken;
};

export const getExpirationAndRenewalTimes = () => {
  const now = new Date().getTime();
  const expirationMs = 3600 * 1000;
  const renewalMs = Number(process.env.REACT_APP_REFRESH_TOKEN_TIME);

  const expiresAt = getTime(addMilliseconds(now, expirationMs));
  const renewAt = getTime(addMilliseconds(now, renewalMs));

  return [expiresAt, renewAt];
};

export const isExpired = (expiresAt: number) => {
  const now = new Date().getTime();
  return now > expiresAt;
};

export const writeLocalStorage = ({ ...pieces }) => {
  const stringified = localStorage.getItem("pazienti_auth");

  if (stringified) {
    const authState = JSON.parse(stringified);

    localStorage.setItem(
      "pazienti_auth",
      JSON.stringify({
        ...authState,
        ...pieces,
      }),
    );
  } else {
    localStorage.setItem("pazienti_auth", JSON.stringify(pieces));
  }
};

export const clearLocalStorage = () => {
  localStorage.removeItem("pazienti_auth");
};

export const hydrateFromLocalStorage = (): IHydrateFromLocalStorage => {
  const stringified = localStorage.getItem("pazienti_auth");
  if (!stringified) {
    localStorage.clear();
    return { auth: initialState };
  }

  const { user, token, role, expiresAt, renewAt } = JSON.parse(stringified);
  if (!user || !token || !role || !expiresAt || !renewAt) {
    localStorage.clear();
    return { auth: initialState };
  }

  if (isExpired(Number(expiresAt))) {
    localStorage.clear();
    return { auth: initialState };
  }

  return {
    auth: {
      ...initialState,
      isAuthenticated: true,
      token,
      expiresAt,
      renewAt,
      role,
      user,
    },
  };
};

export const getAuthHeader = (token: string) => {
  return { Authorization: `Bearer ${token}` };
};

const capitalize = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export async function translate(
  languages: Array<string> | undefined,
  capitalized = true,
) {
  if (!languages) return [];

  await checkIfPolyfillNeeded();
  const intl = createIntl({ locale: "it" }, createIntlCache());

  return languages.map((language) => {
    const result = intl.formatDisplayName(language, { type: "language" });

    if (result === undefined) {
      return {
        code: language,
        name: language,
      };
    } else {
      return {
        code: language,
        name: capitalized ? capitalize(result) : result,
      };
    }
  });
}

export const orderLanguages = (languages: Array<ILanguage>) => {
  const preferredCodes = ["zh-CN", "fr", "en", "es-ES", "de"];
  const preferred = languages.filter((language) =>
    preferredCodes.includes(language.code) ? language : undefined,
  );
  const otherLanguages = languages.filter((language) =>
    !preferredCodes.includes(language.code) ? language : undefined,
  );

  return [...preferred, ...otherLanguages];
};

async function checkIfPolyfillNeeded() {
  try {
    if (shouldPolyfillDisplayNames()) {
      await import("@formatjs/intl-displaynames/polyfill");
    }

    if (shouldPolyfillGetCanonicalLocales()) {
      await import("@formatjs/intl-getcanonicallocales/polyfill");
    }

    if (shouldPolyfillLocale()) {
      await import("@formatjs/intl-locale/polyfill");
    }

    // @ts-ignore
    if (Intl.DisplayNames.polyfilled) {
      await import("@formatjs/intl-displaynames/locale-data/it");
    }
  } catch (e) {
    console.error("checkIfPolyfillNeeded:", e);
  }
}

export const getImageAndDocumentKind = (kind: string) => {
  if (kind === "recipe") {
    return [RecipeImage, "Ricetta"];
  } else if (kind === "report") {
    return [ReportImage, "Referto"];
  } else {
    return [OtherImage, "Documento"];
  }
};

export const getDocumentData = (documentType: string) => {
  let theDocument, document, image;

  if (documentType === "recipe") {
    theDocument = "la ricetta";
    document = "ricetta";
    image = RecipeImage;
  } else {
    theDocument = "il referto";
    document = "referto";
    image = ReportImage;
  }

  return { theDocument, document, image };
};

export const getLoginUrl = (state?: string) => {
  let params;

  if (state) {
    params = [
      `client_id=${process.env.REACT_APP_OAUTH_CLIENT_ID}`,
      `redirect_uri=${process.env.REACT_APP_OAUTH_REDIRECT_URI}`,
      `response_type=${process.env.REACT_APP_OAUTH_RESPONSE_TYPE}`,
      `state=${window.location.host}${state}`,
    ].join("&");
  } else {
    params = [
      `client_id=${process.env.REACT_APP_OAUTH_CLIENT_ID}`,
      `redirect_uri=${process.env.REACT_APP_OAUTH_REDIRECT_URI}`,
      `response_type=${process.env.REACT_APP_OAUTH_RESPONSE_TYPE}`,
    ].join("&");
  }

  return `${process.env.REACT_APP_OAUTH_ENDPOINT}?${params}`;
};

type State = string | undefined;

export const getStateFromUrl = () => {
  const { hash } = window.location;
  const splittedHash = hash.split("&");
  const lastParam = splittedHash[splittedHash.length - 1];

  let state: State = undefined;
  if (lastParam.includes("state")) {
    state = decodeURIComponent(lastParam.split("=")[1]);
  }

  return state;
};

export const getPazientiApiEnpoint = () =>
  `${process.env.REACT_APP_PAZIENTI_ENDPOINT}/${process.env.REACT_APP_PAZIENTI_API_ENDPOINT}/${process.env.REACT_APP_PAZIENTI_API_VERSION}`;

export function* checkError(e) {
  if (e.status === 401) {
    yield put(logoutRequest());
    return;
  }
}
