import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
  Reducer,
} from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { connectRouter, routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";

import { hydrateFromLocalStorage } from "utils";
import reducers from "./reducers";
import rootSaga from "./rootSaga";

export const history = createBrowserHistory();

// Reducers
const allReducers = {
  ...reducers,
  router: connectRouter(history) as Reducer,
};

const rootReducer = combineReducers(allReducers);

const preloadedState = hydrateFromLocalStorage();

// Middleware
const sagaMiddleware = createSagaMiddleware();
const middleware = [
  ...getDefaultMiddleware({ thunk: false, serializableCheck: false }),
  routerMiddleware(history),
  sagaMiddleware,
];

// Store
export type RootState = ReturnType<typeof rootReducer>;

const store = configureStore({
  reducer: rootReducer,
  middleware,
  preloadedState,
  devTools:
    process.env.NODE_ENV === "development" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
});

sagaMiddleware.run(rootSaga);

export { store };
