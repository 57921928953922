import React, { FC } from "react";
import { Flex, Text, Image } from "@chakra-ui/react";
import { palette } from "theme/theme";

interface INavLinkItem {
  name: string;
  img: string;
}

const NavLinkItem: FC<INavLinkItem> = ({ name, img }) => (
  <Flex direction="column" justify="center" align="center">
    <Image src={img} boxSize="48px" display={{ base: "none", md: "flex" }} />
    <Text as="span" color={palette.BLUE}>
      {name}
    </Text>
  </Flex>
);

export default NavLinkItem;
