import * as React from "react";
import { FC, useEffect } from "react";
import { Route, RouteProps, useLocation, Redirect } from "react-router-dom";
import { useAuth } from "hooks";
import Redirecting from "pages/Redirecting";
import { getLoginUrl } from "utils";
import { ROLES } from "constants/index";

interface IPrivateRoute extends RouteProps {
  component: any;
  roles?: Array<string>;
  rest?: Record<string, unknown>;
}

type RedirectTo = string | undefined;

const PrivateRoute: FC<IPrivateRoute> = ({
  component: Component,
  roles = [ROLES.doctor],
  ...rest
}) => {
  const location = useLocation();
  const { isAuthenticated, checkTokenExpiration, role } = useAuth();
  let redirectTo: RedirectTo = undefined;

  // If patient trying to visit a consultation (Show page), pass 'state' to login page
  if (!isAuthenticated && location.pathname.includes("show")) {
    redirectTo = location.pathname;
  }

  const loginUrl = getLoginUrl(redirectTo);

  useEffect(() => {
    checkTokenExpiration();
  }, [checkTokenExpiration]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          if (roles.includes(role)) {
            return <Component {...props} />;
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/unauthorized",
                  state: {
                    status: 403,
                  },
                }}
              />
            );
          }
        } else {
          return <Redirecting to={loginUrl} />;
        }
      }}
    />
  );
};

export default PrivateRoute;
