import { getAuthHeader } from "utils";
import { IApiCallGetPatientInfoRequestPayload } from "./types";
import { ROLES } from "constants/index";

export async function getPatientInfo({
  consultationId,
  role,
  token,
}: IApiCallGetPatientInfoRequestPayload) {
  const roleUrlString = role === ROLES.doctor ? "doctors" : "patients";

  try {
    const { patient_info } = await fetch(
      `${process.env.REACT_APP_ENDPOINT}/${roleUrlString}/videocalls/${consultationId}/patient_info`,
      {
        headers: getAuthHeader(token),
      },
    ).then((res) => {
      if (!res.ok) {
        throw res;
      }
      return res.json();
    });

    return {
      fullName: patient_info.full_name,
      address: patient_info.address,
      fiscalCode: patient_info.fiscal_code,
    };
  } catch (e) {
    console.log("getPatientInfo:", e);
    throw e;
  }
}
