import * as React from "react";
import { FC, useEffect, useCallback, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Flex, Text } from "@chakra-ui/react";
import Wrapper from "components/Wrapper";
import { palette } from "theme/theme";

interface IError {
  status: number;
  errorText: string;
}

interface ILocation {
  status?: number;
}

const NotFoundPage: FC = () => {
  const { state } = useLocation<ILocation>();
  const [error, setError] = useState<IError | undefined>(undefined);

  const errors: Array<IError> = useMemo(
    () => [
      {
        status: 404,
        errorText: "Page not found!",
      },
      {
        status: 403,
        errorText: "Unauthorized!",
      },
    ],
    [],
  );

  const getError = useCallback(
    (status: number | undefined) => {
      const error = errors.find((error) => error.status === status);

      // Fallback to 404 if no error
      if (error) {
        setError(error);
      } else {
        setError({
          status: 404,
          errorText: "Page not found!",
        });
      }
    },
    [errors],
  );

  useEffect(() => {
    getError(state?.status);
  }, [getError, state?.status]);

  return (
    <Flex flex="1" bg={palette.LIGHT_BLUE}>
      <Wrapper>
        <Text
          fontSize="2xl"
          fontWeight="bold"
          textAlign="center"
          color={palette.BLUE}
        >
          {error?.status}
        </Text>
        <Text fontSize="xl" textAlign="center">
          {error?.errorText}
        </Text>
        <a href={process.env.REACT_APP_BACK_TO_HOME_ENDPOINT}>
          <Text
            fontSize="xl"
            fontWeight="bold"
            textAlign="center"
            color={palette.BLUE}
          >
            Torna alla Home
          </Text>
        </a>
      </Wrapper>
    </Flex>
  );
};

export default NotFoundPage;
