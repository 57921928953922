import { createSelector } from "@reduxjs/toolkit";
// Fix for circular dependencies error
// import { RootState } from "../store";

const allAppointmentsSelector = (state) => state.appointments.all;
const loadingSelector = (state) => state.appointments.loading;
const currentPageSelector = (state) => state.appointments.currentPage;
const pageCountSelector = (state) => state.appointments.pageCount;

export const reduxAllAppointments = createSelector(
  allAppointmentsSelector,
  (appointments) => appointments,
);

export const reduxIsLoadingAppointments = createSelector(
  loadingSelector,
  (loading) => loading,
);

export const reduxCurrentPage = createSelector(
  currentPageSelector,
  (currentPage) => currentPage,
);

export const reduxPageCount = createSelector(
  pageCountSelector,
  (pageCount) => pageCount,
);

export const reduxGetAppointmentById = ({ consultationId }) => {
  return createSelector(allAppointmentsSelector, (appointments) =>
    appointments.find(
      (appointment) => appointment.id === Number(consultationId),
    ),
  );
};
