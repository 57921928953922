import { createReducer } from "@reduxjs/toolkit";
import {
  getAllAppointmentsByPageRequest,
  getAllAppointmentsByPageSuccess,
  getAllAppointmentsByPageFailed,
  getSingleAppointmentRequest,
  getSingleAppointmentSuccess,
  getSingleAppointmentFailed,
  setCurrentPage,
  resetAppointments,
} from "./actions";
import { IAppointment } from "./types";

export interface IAppointmentsState {
  all: Array<IAppointment>;
  loading: boolean;
  currentPage: number;
  pageCount: number;
  updatedAt: string;
}

const initialState: IAppointmentsState = {
  all: [],
  loading: false,
  currentPage: 1,
  pageCount: 1,
  updatedAt: "",
};

const appointmentsReducer = createReducer(initialState, {
  [getAllAppointmentsByPageRequest.type]: (state) => {
    state.loading = true;
    state.all = initialState.all;
  },
  [getAllAppointmentsByPageSuccess.type]: (state, action) => {
    state.loading = false;
    state.all = action.payload.appointments;
    state.pageCount = action.payload.pageCount;
  },
  [getAllAppointmentsByPageFailed.type]: (state) => {
    state.loading = false;
  },
  [getSingleAppointmentRequest.type]: (state) => {
    state.loading = true;
    state.updatedAt = initialState.updatedAt;
  },
  [getSingleAppointmentSuccess.type]: (state, action) => {
    state.loading = false;
    state.updatedAt = action.payload.updatedAt;
  },
  [getSingleAppointmentFailed.type]: (state) => {
    state.loading = false;
  },
  [setCurrentPage.type]: (state, action) => {
    state.currentPage = action.payload.currentPage;
  },
  [resetAppointments.type]: (state) => {
    state.all = initialState.all;
    state.loading = initialState.loading;
    state.currentPage = initialState.currentPage;
    state.pageCount = initialState.pageCount;
    state.updatedAt = initialState.updatedAt;
  },
});

export default appointmentsReducer;
