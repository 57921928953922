import styled from "@emotion/styled";
import { Box } from "@chakra-ui/react";

const NavBarWrapper = styled(Box)`
  & li {
    text-align: center;
  }
`;

export default NavBarWrapper;
