import React, { FC } from "react";
import { Box } from "@chakra-ui/react";

const NavBarContainer: FC = ({ children }) => (
  <Box
    position="relative"
    w="100%"
    display={{ md: "flex" }}
    justifyContent={{ md: "space-between" }}
    alignItems={{ md: "center" }}
  >
    {children}
  </Box>
);

export default NavBarContainer;
