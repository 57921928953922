import { getAuthHeader, translate, orderLanguages } from "utils";
import {
  IApiCallGetAllAttachmentsRequestPayload,
  IApiCallDeleteSingleAttachmentRequestPayload,
  IApiCallGetSingleAttachmentRequestPayload,
  IApiCallAddAttachmentRequestPayload,
  IApiCallUpdateAttachmentRequestPayload,
  IApiCallDownloadSingleAttachmentRequestPayload,
  IApiCallGetPresignedPostData,
} from "./types";
import { ROLES } from "constants/index";

export async function getAllAttachments({
  consultationId,
  token,
}: IApiCallGetAllAttachmentsRequestPayload) {
  const response = await fetch(
    `${process.env.REACT_APP_ENDPOINT}/doctors/videocalls/${consultationId}`,
    {
      headers: getAuthHeader(token),
    },
  ).then((res) => {
    if (!res.ok) {
      throw res;
    }

    return res.json();
  });

  return {
    attachments: response.attachments,
    consultationExecutedAt: response.executed_at,
  };
}

export async function deleteSingleAttachment({
  consultationId,
  uuid,
  token,
}: IApiCallDeleteSingleAttachmentRequestPayload) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ENDPOINT}/doctors/videocalls/${consultationId}/attachments/${uuid}`,
      {
        method: "DELETE",
        headers: getAuthHeader(token),
      },
    ).then((res) => {
      if (!res.ok) {
        throw res;
      }

      return res.json();
    });

    return response.attachment.uuid;
  } catch (e) {
    console.log("deleteSingleAttachment:", e);
  }
}

export async function getSingleAttachment({
  consultationId,
  documentId,
  token,
}: IApiCallGetSingleAttachmentRequestPayload) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ENDPOINT}/doctors/videocalls/${consultationId}`,
      {
        headers: getAuthHeader(token),
      },
    ).then((res) => {
      if (!res.ok) {
        throw res;
      }

      return res.json();
    });

    const document = response.attachments.find(
      (attachment) => attachment.uuid === documentId,
    );

    if (document === undefined) {
      throw new Error("Document not found");
    }

    if (response.status !== "enabled") {
      throw new Error("Document not found");
    }

    return document;
  } catch (e) {
    console.log("getSingleAttachment:", e);
  }
}

export async function addAttachment({
  consultationId,
  text,
  kind,
  languages,
  token,
}: IApiCallAddAttachmentRequestPayload) {
  try {
    const languageCodes = languages
      .filter((language) => language.checked)
      .map((language) => language.code);

    await fetch(
      `${process.env.REACT_APP_ENDPOINT}/doctors/videocalls/${consultationId}/text_attachments`,
      {
        method: "POST",
        body: JSON.stringify({ text, kind, languages: languageCodes }),
        headers: {
          ...getAuthHeader(token),
          "Content-Type": "application/json",
        },
      },
    ).then((res) => {
      if (!res.ok) {
        throw res;
      }

      return res.json();
    });
  } catch (e) {
    console.log("addAttachment:", e);
    throw e;
  }
}

export async function getPresignedPostData({
  body,
  consultationId,
  token,
}: IApiCallGetPresignedPostData) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_ENDPOINT}/doctors/videocalls/${consultationId}/file_attachments`,
      {
        method: "POST",
        headers: {
          ...getAuthHeader(token),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      },
    ).then((res) => {
      if (!res.ok) {
        throw res;
      }

      return res.json();
    });

    return response;
  } catch (e) {
    console.log("getPresignedPostData:", e);
    throw e;
  }
}

export async function updateAttachment({
  consultationId,
  documentId,
  text,
  languages,
  token,
}: IApiCallUpdateAttachmentRequestPayload) {
  try {
    const languageCodes = languages
      .filter((language) => language.checked)
      .map((language) => language.code);

    await fetch(
      `${process.env.REACT_APP_ENDPOINT}/doctors/videocalls/${consultationId}/text_attachments/${documentId}`,
      {
        method: "PUT",
        body: JSON.stringify({ text, languages: languageCodes }),
        headers: {
          ...getAuthHeader(token),
          "Content-Type": "application/json",
        },
      },
    ).then((res) => {
      if (!res.ok) {
        throw res;
      }

      return res.json();
    });
  } catch (e) {
    console.log("updateAttachment:", e);
    throw e;
  }
}

export async function getLanguages() {
  try {
    const { data } = await fetch("https://api.modernmt.com/languages").then(
      (res) => {
        if (!res.ok) {
          throw res;
        }

        return res.json();
      },
    );

    const translated = await translate(data.it);
    const ordered = orderLanguages(translated);
    return ordered;
  } catch (e) {
    console.error("getLanguages:", e);
    throw e;
  }
}

export async function uploadFile({ presignedPostData, formData, kind }) {
  try {
    const response = await fetch(presignedPostData.url, {
      method: "POST",
      body: formData,
    }).then((res) => {
      if (!res.ok || res.status !== 201) {
        throw res;
      }

      const uuid = presignedPostData.url_fields.key.split("/")[1];

      return {
        uuid,
        type: "FileAttachment",
        kind,
      };
    });

    return response;
  } catch (e) {
    console.log("uploadFile:", e);
    throw e;
  }
}

export async function getDownloadLink({
  consultationId,
  uuid,
  languageCode,
  role,
  token,
}: IApiCallDownloadSingleAttachmentRequestPayload) {
  try {
    const roleUrlString = role === ROLES.doctor ? "doctors" : "patients";
    const url =
      languageCode === ""
        ? `${process.env.REACT_APP_ENDPOINT}/${roleUrlString}/videocalls/${consultationId}/attachments/${uuid}`
        : `${process.env.REACT_APP_ENDPOINT}/${roleUrlString}/videocalls/${consultationId}/attachments/${uuid}?language=${languageCode}`;

    const response = await fetch(url, { headers: getAuthHeader(token) }).then(
      (res) => {
        if (!res.ok) {
          throw res;
        }

        return res.json();
      },
    );

    return response.url;
  } catch (e) {
    console.log("getDownloadLink:", e);
    throw e;
  }
}
