import * as React from "react";
import { FC } from "react";
import { Text, Box } from "@chakra-ui/react";
import { palette } from "theme/theme";
import parse from "html-react-parser";
import FaqItemWrapper from "components/Faq/FaqItemWrapper/styled";

export interface IFaqItem {
  question: string;
  answer: string;
}

const FaqItem: FC<IFaqItem> = ({ question, answer }) => (
  <FaqItemWrapper>
    <Text fontWeight="bold" fontSize="2xl" color={palette.BLUE}>
      {question}
    </Text>
    <Box color={palette.DARK_GRAY} listStylePosition="inside">
      {parse(answer)}
    </Box>
  </FaqItemWrapper>
);

export default FaqItem;
