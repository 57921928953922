import * as React from "react";
import { FC, useState, useEffect, useCallback, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams, useHistory, useLocation } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  Button,
  Textarea,
  useToast,
  Skeleton,
} from "@chakra-ui/react";
import Wrapper from "components/Wrapper";
import Checkbox from "components/Checkbox";
import Modal from "components/Modal";
import { getSignatureRequest } from "redux/auth/actions";
import {
  reduxUser,
  reduxDocumentHeader,
  reduxHasSignature,
  reduxIsCheckingSignature,
} from "redux/auth/selectors";
import {
  setCheckedLanguages,
  getSingleAttachmentRequest,
  getLanguagesRequest,
  cleanActiveAttachment,
} from "redux/attachments/actions";
import {
  reduxAttachmentText,
  reduxIsLoadingAttachments,
  reduxIsLoadingLanguages,
  reduxCheckedAndOrderedLanguages,
} from "redux/attachments/selectors";
import { getPatientInfoRequest } from "redux/patient/actions";
import { reduxPatient, reduxIsLoadingPatient } from "redux/patient/selectors";
import theme, { palette } from "theme/theme";
import { DISCLAIMER_WRITE_PRESCRIPTION } from "constants/index";

interface ICheckedLanguage {
  code: string;
  name: string;
  checked: boolean;
}

interface IOnSubmit {
  consultationId: string;
  documentId?: string;
  kind?: string;
  text: string;
  languages: Array<ICheckedLanguage>;
}

interface IFormDocument {
  mode: "Create" | "Edit";
  onSubmit: (data: IOnSubmit) => void;
}

interface IParams {
  consultationId: string;
  documentType: "recipe" | "report";
  documentId: string;
}

const FormDocument: FC<IFormDocument> = ({ mode, onSubmit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { consultationId, documentType, documentId } = useParams<IParams>();
  const toast = useToast();
  const userData = useSelector(reduxUser);
  const documentHeader = useSelector(reduxDocumentHeader);
  const hasSignature = useSelector(reduxHasSignature);
  const isCheckingSignature = useSelector(reduxIsCheckingSignature);
  const user = `${userData!.firstName} ${userData!.lastName}`;

  const {
    fullName: patientFullName,
    address: patientAddress,
    fiscalCode: patientFiscalCode,
  } = useSelector(reduxPatient);

  const checkedAndOrderedLanguages = useSelector(
    reduxCheckedAndOrderedLanguages,
  );
  const attachmentText = useSelector(reduxAttachmentText);
  const TRANSLATIONS_ACTIVE = process.env.REACT_APP_TRANSLATIONS_ACTIVE;
  const SIGNATURE_ACTIVE = process.env.REACT_APP_SIGNATURE_ACTIVE;

  const isLoadingPatient = useSelector(reduxIsLoadingPatient);
  const isLoadingLanguages = useSelector(reduxIsLoadingLanguages);
  const isLoadingAttachments = useSelector(reduxIsLoadingAttachments);
  const allowedDocuments = useRef([
    { param: "recipe", name: "ricetta" },
    { param: "report", name: "referto" },
  ]).current;

  const findAllowedDocument = useCallback((el) => el.param === documentType, [
    documentType,
  ]);

  const allowedIndex = useMemo(
    () => allowedDocuments.findIndex(findAllowedDocument),
    [allowedDocuments, findAllowedDocument],
  );
  const documentName = useRef(allowedDocuments[allowedIndex]?.name).current;

  const [textarea, setTextarea] = useState(attachmentText);
  const [showAll, setShowAll] = useState(false);
  const isDisabled = useMemo(
    () => textarea.length === 0 || isLoadingAttachments || !documentHeader,
    [textarea.length, isLoadingAttachments, documentHeader],
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleisModalOpen = useCallback(
    () => setIsModalOpen((prevOpen) => !prevOpen),
    [],
  );

  useEffect(() => {
    dispatch(getPatientInfoRequest({ consultationId }));
  }, [dispatch, consultationId]);

  useEffect(() => {
    if (SIGNATURE_ACTIVE === "true") {
      dispatch(getSignatureRequest());
    }
  }, [dispatch, SIGNATURE_ACTIVE]);

  useEffect(() => {
    if (
      !documentHeader ||
      (SIGNATURE_ACTIVE === "true" &&
        hasSignature === false &&
        isCheckingSignature === false)
    ) {
      setIsModalOpen(true);
    }
  }, [documentHeader, SIGNATURE_ACTIVE, hasSignature, isCheckingSignature]);

  useEffect(() => {
    return () => {
      dispatch(cleanActiveAttachment());
    };
  }, [dispatch]);

  const fetchAttachment = useCallback(() => {
    if (mode === "Edit") {
      dispatch(getSingleAttachmentRequest({ consultationId, documentId }));
    }
  }, [dispatch, mode, consultationId, documentId]);

  useEffect(() => {
    fetchAttachment();
  }, [fetchAttachment]);

  const fetchLanguages = useCallback(() => {
    if (mode === "Create" && TRANSLATIONS_ACTIVE === "true") {
      dispatch(getLanguagesRequest());
    }
  }, [dispatch, mode, TRANSLATIONS_ACTIVE]);

  useEffect(() => {
    fetchLanguages();
  }, [fetchLanguages]);

  useEffect(() => {
    setTextarea(attachmentText);
  }, [attachmentText]);

  const toggleShowAll = useCallback(
    () => setShowAll((showAll) => !showAll),
    [],
  );

  const handleTextarea = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => setTextarea(e.target.value),
    [],
  );

  const handleLanguageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name } = e.target;

      const incomingLanguage = checkedAndOrderedLanguages.find(
        (language) => language.code === name,
      );
      const otherLanguages = checkedAndOrderedLanguages.filter(
        (language) => language.code !== name,
      );

      const checkedLanguagesCount = checkedAndOrderedLanguages.filter(
        (language) => language.checked,
      ).length;

      if (checkedLanguagesCount >= 5 && !incomingLanguage!.checked) {
        toast.closeAll();
        toast({
          title: "Attenzione",
          description: `E' possibile selezionare un massimo di 5 lingue per la traduzione del documento.`,
          position: "top",
          status: "error",
          duration: 2500,
          isClosable: true,
        });
      }

      const isChecked = incomingLanguage.checked === true;

      if (isChecked) {
        dispatch(
          setCheckedLanguages({
            languages: [
              ...otherLanguages,
              { ...incomingLanguage, checked: false },
            ],
          }),
        );
      } else {
        dispatch(
          setCheckedLanguages({
            languages: [
              { ...incomingLanguage, checked: true },
              ...otherLanguages,
            ],
          }),
        );
      }
    },
    [dispatch, toast, checkedAndOrderedLanguages],
  );

  const renderCheckboxes = useCallback(
    () =>
      checkedAndOrderedLanguages.map(({ code, name, checked }, i) => (
        <Checkbox
          key={code}
          code={code}
          name={name}
          isChecked={checked}
          onChange={handleLanguageChange}
          visible={i <= 4 ? true : showAll}
        />
      )),
    [checkedAndOrderedLanguages, showAll, handleLanguageChange],
  );

  const navigateToProfilePage = useCallback(
    () => history.push("/profile", { from: location.pathname }),
    [history, location.pathname],
  );

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (TRANSLATIONS_ACTIVE === "true") {
        const languagesLength = checkedAndOrderedLanguages.filter(
          (language) => language.checked,
        ).length;

        if (languagesLength > 5) {
          toast({
            title: "Attenzione",
            description: `E' possibile selezionare un massimo di 5 lingue per la traduzione del documento.`,
            position: "top",
            status: "error",
            duration: 2500,
            isClosable: true,
          });
          return;
        }
      }

      // Pass data to NewCreate component, where dispatching of action occurs
      if (mode === "Create") {
        onSubmit({
          consultationId,
          kind: documentType,
          languages: checkedAndOrderedLanguages,
          text: textarea,
        });
      } else {
        onSubmit({
          consultationId,
          documentId,
          languages: checkedAndOrderedLanguages,
          text: textarea,
        });
      }
    },
    [
      mode,
      onSubmit,
      toast,
      checkedAndOrderedLanguages,
      consultationId,
      documentId,
      documentType,
      textarea,
      TRANSLATIONS_ACTIVE,
    ],
  );

  if (allowedIndex === -1) {
    return <Redirect to="/" />;
  }

  return (
    <Flex
      flex="1"
      direction="column"
      justify="center"
      bg={palette.LIGHT_BLUE}
      py="20px"
    >
      <form onSubmit={handleSubmit}>
        <Wrapper>
          <Text
            fontSize="3xl"
            fontWeight="bold"
            textAlign="center"
            textTransform="uppercase"
            color={palette.BLUE}
          >
            {documentName}
          </Text>

          <Flex
            w="100%"
            direction={{ base: "column", md: "row" }}
            justify={{ base: "flex-start", md: "space-between" }}
            color={palette.DARK_GRAY}
          >
            <Box>
              <Text
                fontWeight="bold"
                display={{ base: "block", md: "none" }}
                color={palette.BLUE}
              >
                Mittente
              </Text>
              <Text fontWeight="bold">{user}</Text>
              <Text whiteSpace="pre-line">{documentHeader}</Text>
            </Box>
            <Box
              ml={{ base: "0", md: "20px" }}
              mt={{ base: "20px", md: "0" }}
              alignSelf="flex-start"
            >
              <Text
                fontWeight="bold"
                display={{ base: "block", md: "none" }}
                color={palette.BLUE}
              >
                Destinatario
              </Text>
              {isLoadingPatient ? (
                <>
                  <Skeleton
                    startColor={palette.GRAY}
                    endColor={palette.DARKER_GRAY}
                    isLoaded={!isLoadingPatient}
                    height={!isLoadingPatient ? "auto" : "24px"}
                  />
                  <Skeleton
                    startColor={palette.GRAY}
                    endColor={palette.DARKER_GRAY}
                    isLoaded={!isLoadingPatient}
                    height={!isLoadingPatient ? "auto" : "24px"}
                  />
                  <Skeleton
                    startColor={palette.GRAY}
                    endColor={palette.DARKER_GRAY}
                    isLoaded={!isLoadingPatient}
                    height={!isLoadingPatient ? "auto" : "24px"}
                  />
                </>
              ) : (
                <>
                  <Text fontWeight="bold">{patientFullName}</Text>
                  <Text>{patientAddress}</Text>
                  <Text>{patientFiscalCode}</Text>
                </>
              )}
            </Box>
          </Flex>

          <Textarea
            value={textarea}
            onChange={handleTextarea}
            bg={palette.WHITE}
            color={palette.DARK_GRAY}
            placeholder={isLoadingAttachments ? "Caricamento..." : "Scrivi qui"}
            resize="none"
            fontSize="2xl"
            p="30px 50px"
            my="30px"
            h="180px"
            boxShadow="prescription"
            borderRadius="15px"
            _hover={{ boxShadow: theme.shadows.prescription }}
            _focus={{ boxShadow: theme.shadows.prescription }}
          />

          {TRANSLATIONS_ACTIVE === "true" ? (
            <>
              <Flex w="100%" direction="column" align="center">
                <Text fontSize="2xl" fontWeight="bold" color={palette.BLUE}>
                  Traduci:
                </Text>
                <Text
                  textAlign="center"
                  fontSize="xs"
                  mb="10px"
                  color={palette.BLUE}
                >
                  {DISCLAIMER_WRITE_PRESCRIPTION}
                </Text>

                <Skeleton
                  as="div"
                  display="flex"
                  w="70%"
                  startColor={palette.GRAY}
                  endColor={palette.DARKER_GRAY}
                  isLoaded={!isLoadingLanguages}
                  height={!isLoadingLanguages ? "auto" : "60px"}
                >
                  <Flex w="100%" wrap="wrap" mt="-20px">
                    {renderCheckboxes()}
                  </Flex>
                </Skeleton>
              </Flex>
              <Flex justify="center" align="center" mt="10px">
                <Text
                  cursor="pointer"
                  fontWeight="bold"
                  color={palette.BLUE}
                  onClick={toggleShowAll}
                >
                  {showAll ? "Nascondi" : "Mostra tutte le lingue"}
                </Text>
              </Flex>
            </>
          ) : null}
        </Wrapper>

        <Flex
          justify={{ base: "center", sm: "flex-end" }}
          mx="auto"
          px={{ base: "20px", md: "40px" }}
          w="100%"
          maxW="1200px"
        >
          <Button
            color={palette.WHITE}
            isDisabled={isDisabled}
            colorScheme={isDisabled ? "myGray" : "myBlue"}
            px="40px"
            textTransform="uppercase"
            fontWeight="bold"
            type="submit"
          >
            Salva
          </Button>
        </Flex>
      </form>

      <Modal
        open={isModalOpen}
        handleModalState={toggleisModalOpen}
        handleAction={navigateToProfilePage}
        title="Attenzione"
        content="Non hai ancora inserito tutti i dati richiesti: verrai rediretto alla sezione Dati intestazione, dove potrai inserire i dati mancanti."
        actionText="Prosegui"
        hasSecondaryButton={false}
        isClosable={false}
      />
    </Flex>
  );
};

export default FormDocument;
