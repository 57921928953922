import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import NavBarWrapper from "components/Nav/NavBarWrapper/styled";
import NavLinkItem from "components/Nav/NavLinkItem";
import HomeImage from "assets/home.png";
import RefertiRicetteImage from "assets/referti_e_ricette.png";
import FaqsImage from "assets/faqs.png";
import DatiIntestazioneImage from "assets/header_data.png";

interface INavBar {
  active: boolean;
}

const NavBar: FC<INavBar> = ({ active }) => {
  return (
    <NavBarWrapper
      as="ul"
      listStyleType="none"
      display={{
        base: active ? "block" : "none",
        md: "flex",
      }}
    >
      <Box
        as="li"
        textAlign="center"
        margin={{
          base: "15px auto",
          md: "0",
        }}
      >
        <a href={process.env.REACT_APP_BACK_TO_HOME_ENDPOINT}>
          <NavLinkItem name="Home" img={HomeImage} />
        </a>
      </Box>
      <Box
        as="li"
        textAlign="center"
        margin={{
          base: "15px auto",
          md: "0 0 0 40px",
        }}
      >
        <NavLink exact to="/" activeClassName="navbar__link--active">
          <NavLinkItem name="Referti e Ricette" img={RefertiRicetteImage} />
        </NavLink>
      </Box>
      <Box
        as="li"
        textAlign="center"
        margin={{
          base: "15px auto",
          md: "0 0 0 40px",
        }}
      >
        <NavLink to="/profile" activeClassName="navbar__link--active">
          <NavLinkItem name="Dati intestazione" img={DatiIntestazioneImage} />
        </NavLink>
      </Box>
      <Box
        as="li"
        textAlign="center"
        margin={{
          base: "15px auto",
          md: "0 0 0 40px",
        }}
      >
        <NavLink to="/faqs" activeClassName="navbar__link--active">
          <NavLinkItem name="Domande" img={FaqsImage} />
        </NavLink>
      </Box>
    </NavBarWrapper>
  );
};

export default NavBar;
