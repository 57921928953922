import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

const FormSection = styled(Box)`
  & + & {
    margin-top: 30px;
  }

  button {
    margin-top: 20px;
  }
`;

export default FormSection;
