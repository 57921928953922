import { PayloadAction } from "@reduxjs/toolkit";
import { takeLatest, call, put, all, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import {
  getUserRequest,
  getUserSuccess,
  getUserFailed,
  logoutRequest,
  resetAuth,
  getNewTokenRequest,
  getNewTokenSuccess,
  getNewTokenFailed,
  getRoleSuccess,
  getRoleFailed,
  refreshAuthRequest,
  updateUserInfoRequest,
  updateUserInfoSuccess,
  updateUserInfoFailed,
  uploadSignatureRequest,
  uploadSignatureSuccess,
  uploadSignatureFailed,
  getSignatureRequest,
  getSignatureSuccess,
  getSignatureFailed,
} from "./actions";
import {
  IGetUserRequestPayload,
  IUpdateUserInfoRequestPayload,
  IUploadSignatureRequest,
} from "./types";
import * as AuthApi from "./apiCall";
import {
  getExpirationAndRenewalTimes,
  writeLocalStorage,
  clearLocalStorage,
  checkError,
} from "utils";
import { reduxToken, reduxUser } from "./selectors";
import { setMessage } from "redux/messages/actions";

function* userSaga({ payload }: PayloadAction<IGetUserRequestPayload>) {
  const { token, redirectTo } = payload;

  try {
    const [user, identity] = yield all([
      call(AuthApi.getUser, { token }),
      call(AuthApi.getIdentity, { token }),
    ]);

    const role = identity?.roles[0];
    if (!role) {
      throw new Error("Unable to get user role");
    }

    const [expiresAt, renewAt] = getExpirationAndRenewalTimes();
    yield put(getUserSuccess({ user, expiresAt, renewAt }));
    yield put(getRoleSuccess({ role }));
    writeLocalStorage({ user, token, role, expiresAt, renewAt });

    if (redirectTo) {
      const indexOf = redirectTo.indexOf("/");
      const redirectUrl = redirectTo.substring(indexOf);
      yield put(push(redirectUrl));
    } else {
      yield put(push("/"));
    }
  } catch (e) {
    yield checkError(e);
    yield put(getUserFailed());
    yield put(getRoleFailed());
  }
}

function* newTokenSaga() {
  const token = yield select(reduxToken);

  try {
    const [newToken, identity] = yield all([
      call(AuthApi.getNewToken, { token }),
      call(AuthApi.getIdentity, { token }),
    ]);

    const role = identity?.roles[0];
    if (!role) {
      throw new Error("Unable to get user role");
    }

    const [expiresAt, renewAt] = getExpirationAndRenewalTimes();
    yield put(getNewTokenSuccess({ token: newToken, expiresAt, renewAt }));
    yield put(getRoleSuccess({ role }));
    writeLocalStorage({ token: newToken, role, expiresAt, renewAt });
  } catch (e) {
    yield checkError(e);
    yield put(getNewTokenFailed());
    yield put(getRoleFailed());
  }
}

function* logoutSaga() {
  const token = yield select(reduxToken);
  yield call(AuthApi.deleteSsoSession, { token });
  yield put(resetAuth());
  clearLocalStorage();
  window.location.href = process.env.REACT_APP_BACK_TO_HOME_ENDPOINT;
}

function* refreshAuthSaga() {
  const token = yield select(reduxToken);

  try {
    const [user, newToken, identity] = yield all([
      call(AuthApi.getUser, { token }),
      call(AuthApi.getNewToken, { token }),
      call(AuthApi.getIdentity, { token }),
    ]);

    const role = identity?.roles[0];
    if (!role) {
      throw new Error("Unable to get user role");
    }

    const [expiresAt, renewAt] = getExpirationAndRenewalTimes();
    yield put(getUserSuccess({ user, expiresAt, renewAt }));
    yield put(getNewTokenSuccess({ token: newToken, expiresAt, renewAt }));
    yield put(getRoleSuccess({ role }));
    writeLocalStorage({ user, token: newToken, role, expiresAt, renewAt });
  } catch (e) {
    yield checkError(e);
    yield put(getNewTokenFailed());
    yield put(getRoleFailed());
  }
}

function* updateUserInfoSaga({
  payload,
}: PayloadAction<IUpdateUserInfoRequestPayload>) {
  const token = yield select(reduxToken);
  const user = yield select(reduxUser);
  const { data, redirectTo } = payload;

  try {
    const body = yield call(AuthApi.updateUserInfo, { token, data, user });
    yield put(updateUserInfoSuccess({ user: body }));
    writeLocalStorage({ user: body });
    yield put(
      setMessage({
        title: "Congratulazioni",
        type: "success",
        description: "L'aggiornamento dei dati è stato eseguito con successo.",
      }),
    );

    if (redirectTo) {
      yield put(push(redirectTo));
    }
  } catch (e) {
    yield checkError(e);
    yield put(
      setMessage({
        title: "Attenzione",
        type: "error",
        description:
          "Si è verificato un errore durante l'aggiornamento dei dati.",
      }),
    );
    yield put(updateUserInfoFailed());
  }
}

function* startSignatureUpload(file) {
  const token = yield select(reduxToken);
  const body = {
    file_ext: file.type.split("/")[1],
  };

  const presignedPostData = yield call(AuthApi.getPresignedPostData, {
    body,
    token,
  });

  const formData = new FormData();
  Object.keys(presignedPostData.url_fields).forEach((key) => {
    formData.append(key, presignedPostData.url_fields[key]);
  });
  formData.append("file", file);

  const result = yield call(AuthApi.uploadSignature, {
    presignedPostData,
    formData,
  });

  return result;
}

function* uploadSignatureSaga({
  payload,
}: PayloadAction<IUploadSignatureRequest>) {
  const { signature } = payload;

  try {
    yield call(startSignatureUpload, signature);

    yield put(
      setMessage({
        title: "Congratulazioni",
        type: "success",
        description: "Caricamento della firma eseguito con successo.",
      }),
    );
    yield put(uploadSignatureSuccess());
  } catch (e) {
    yield checkError(e);
    yield put(
      setMessage({
        title: "Attenzione",
        type: "error",
        description: "Si è verificato un errore durante l'upload della firma.",
      }),
    );
    yield put(uploadSignatureFailed());
  }
}

function* getSignatureSaga() {
  const token = yield select(reduxToken);

  try {
    const url = yield call(AuthApi.getSignature, { token });
    yield put(getSignatureSuccess({ hasSignature: url !== "" }));
  } catch (e) {
    yield checkError(e);
    yield put(
      setMessage({
        title: "Attenzione",
        type: "error",
        description:
          "Si è verificato un errore durante il controllo della firma.",
      }),
    );
    yield put(getSignatureFailed());
  }
}

export default function* authSaga() {
  yield takeLatest(getUserRequest.type, userSaga);
  yield takeLatest(getNewTokenRequest.type, newTokenSaga);
  yield takeLatest(logoutRequest.type, logoutSaga);
  yield takeLatest(refreshAuthRequest.type, refreshAuthSaga);
  yield takeLatest(updateUserInfoRequest.type, updateUserInfoSaga);
  yield takeLatest(uploadSignatureRequest.type, uploadSignatureSaga);
  yield takeLatest(getSignatureRequest.type, getSignatureSaga);
}
