import { Box } from "@chakra-ui/react";
import styled from "@emotion/styled";

const FaqItemWrapper = styled(Box)`
  & + & {
    margin-top: 20px;
  }
`;

export default FaqItemWrapper;
