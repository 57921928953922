import * as React from "react";
import { FC, useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Box, Flex, Image } from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useAuth } from "hooks";
import { palette } from "theme/theme";
import { ROLES } from "constants/index";
import NavBar from "components/Nav/NavBar";
import Toggle from "components/Nav/Toggle";
import Logo from "assets/logo.svg";
import NavBarContainer from "components/Nav/NavBarContainer";

const Header: FC = () => {
  const { pathname } = useLocation();
  const { isAuthenticated, role } = useAuth();
  const variant = pathname === "/" ? palette.WHITE : palette.LIGHT_BLUE;

  const [active, setActive] = useState(false);
  const onClickHandler = useCallback(() => setActive(!active), [active]);

  // Hide mobile navbar on page change
  useEffect(() => {
    setActive(false);
  }, [pathname]);

  return (
    <Box zIndex={{ base: 9999, lg: 99 }} py="4" bg={variant}>
      <Flex
        justify="space-between"
        mx="auto"
        px={{ base: "20px", md: "40px" }}
        maxW="1200px"
      >
        <NavBarContainer>
          <a href={process.env.REACT_APP_BACK_TO_HOME_ENDPOINT}>
            <Image src={Logo} w={{ base: "150px", md: "250px" }} />
          </a>

          {isAuthenticated && role === ROLES.doctor ? (
            <>
              <NavBar active={active} />

              <Toggle onClickHandler={onClickHandler}>
                <HamburgerIcon color="myBlue" h={6} w={6} />
              </Toggle>
            </>
          ) : null}
        </NavBarContainer>
      </Flex>
    </Box>
  );
};

export default Header;
