export const DISCLAIMER_TITLE = "Disclaimer";

export const DISCLAIMER_WRITE_PRESCRIPTION =
  "Il paziente è a conoscenza che la traduzione del documento avverrà mediante uno strumento automatico di traduzione. Il paziente riceverà, per la traduzione, un file pdf contenente solo il testo tradotto.";

export const SIZE_LIMIT = 41943040; // 40 Mb
export const SIGNATURE_LIMIT = 10485760; // 10 Mb

export const nonExpiryAccessToken =
  "u6Dc55l_twcopJFTpRhdTEjzW5XjLnPwdet5omNX_ec";

export const ROLES = {
  doctor: "doctor",
  member: "member",
};
