import { createSelector } from "@reduxjs/toolkit";

const patientSelector = (state) => state.patient.currentPatient;
const loadingSelector = (state) => state.patient.loading;

export const reduxPatient = createSelector(
  patientSelector,
  (patient) => patient,
);

export const reduxIsLoadingPatient = createSelector(
  loadingSelector,
  (loading) => loading,
);
