import * as React from 'react';
import { FC, memo } from 'react';
import { Box, Text, VisuallyHidden, ControlBox, Image } from '@chakra-ui/react';
import { palette } from 'theme/theme';
import SelectionImage from 'assets/selection.png';

interface ICheckbox {
  name: string;
  code: string;
  isChecked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  visible: boolean;
}

const Checkbox: FC<ICheckbox> = memo(({ name, code, isChecked, onChange, visible = true }) => (
  <Box
    w={{ base: '50%', sm: '33%', md: '20%' }}
    mt='20px'
    display={visible ? 'block' : 'none'}
  >
    <Box
      as='label'
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      cursor='pointer'
    >
      {/* https://github.com/chakra-ui/chakra-ui/issues/192 */}
      {/*
      // @ts-ignore*/}
      <VisuallyHidden type='checkbox'
        as='input'
        onChange={onChange}
        id={code}
        name={code}
        defaultChecked={isChecked}
      />

      <ControlBox
        boxSize='30px'
        borderWidth='2px'
        borderColor={palette.BLUE}
        borderRadius='8px'
        overflow='hidden'
      >
        <Image boxSize='30px' src={SelectionImage} />
      </ControlBox>

      <Text as='span' textAlign='center' color={palette.BLUE} mt='5px'>
        {name}
      </Text>
    </Box>
  </Box>
));

export default Checkbox;
