import * as React from "react";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import Wrapper from "components/Wrapper";
import { palette } from "theme/theme";
import { getUserRequest } from "redux/auth/actions";
import { getTokenFromUrl, getLoginUrl, getStateFromUrl } from "utils";

const AuthCallbackPage: FC = () => {
  const dispatch = useDispatch();
  const loginUrl = getLoginUrl();

  useEffect(() => {
    // Return to login page if token is not found
    const token = getTokenFromUrl();
    const redirectTo = getStateFromUrl();
    if (!token) {
      window.location.href = loginUrl;
      return;
    }

    // Call endpoint passing in access_token from URL. If redirectTo property is provided, send user to that URL after login
    if (redirectTo) {
      dispatch(getUserRequest({ token, redirectTo }));
    } else {
      dispatch(getUserRequest({ token }));
    }
  }, [dispatch, loginUrl]);

  return (
    <Flex flex="1" bg={palette.LIGHT_BLUE}>
      <Wrapper>
        <Spinner color={palette.BLUE} />
        <Text color={palette.BLUE}>Redirect in corso...</Text>
      </Wrapper>
    </Flex>
  );
};

export default AuthCallbackPage;
