import * as React from "react";
import { FC } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Flex,
  Text,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { palette } from "theme/theme";
import UploadImage from "assets/upload.png";
import { getDocumentData } from "utils";
import Uploader from "components/Uploader";

interface IFormat {
  allowedFormats: string;
  allowedMimeTypes: string;
}

interface IModalWriteOrLoad {
  open: boolean;
  consultationId: string;
  handleModalState: () => void;
  handleFile: (arg: FileList | File | null, format: IFormat) => void;
  documentType: "recipe" | "report" | "other";
  hiddenRef: React.MutableRefObject<HTMLInputElement>;
}

const ModalWriteOrLoad: FC<IModalWriteOrLoad> = ({
  open,
  consultationId,
  handleModalState,
  handleFile,
  documentType,
  hiddenRef,
}) => {
  const { theDocument, document, image } = getDocumentData(documentType);
  const title = `Compila o carica ${theDocument} da inviare al paziente`;

  return (
    <Modal size="xl" isOpen={open} onClose={handleModalState} isCentered>
      <ModalOverlay />
      <ModalContent backgroundColor={palette.WHITE} borderRadius="25px">
        <ModalHeader
          textAlign="center"
          w="70%"
          m="0 auto"
          color={palette.BLUE}
          fontWeight="bold"
        >
          {title}
        </ModalHeader>
        <ModalCloseButton color="#87c8df" />

        <ModalBody
          d="flex"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent={{ base: "center" }}
          w="70%"
          m="0 auto"
          color={palette.BLUE}
        >
          <Flex
            direction="column"
            align="center"
            mt={{ base: "20px", md: "0" }}
            cursor="pointer"
          >
            <Link to={`/consultation/${consultationId}/${documentType}/write`}>
              <Image src={image} boxSize="70px" m="0 auto" />
              <Text
                fontSize="xl"
                fontWeight="bold"
                textTransform="uppercase"
                mt="20px"
                textAlign="center"
              >
                Compila {document}
              </Text>
            </Link>
          </Flex>
          <Box ml={{ base: "0", md: "40px" }}>
            <Flex
              direction="column"
              align="center"
              mt={{ base: "20px", md: "0" }}
              cursor="pointer"
              onClick={!open ? () => null : () => hiddenRef.current.click()}
            >
              <Image src={UploadImage} boxSize="70px" m="0 auto" />
              <Text
                fontSize="xl"
                fontWeight="bold"
                textTransform="uppercase"
                textAlign="center"
                mt="20px"
                color={palette.BLUE}
              >
                {`Carica ${document}`}
              </Text>
              <Uploader
                multiple
                formats=".pdf, .png, .jpeg, .jpg"
                accept="application/pdf, image/png, image/jpeg"
                handleFile={handleFile}
                ref={hiddenRef}
              />
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalWriteOrLoad;
